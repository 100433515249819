import React from 'react';
import * as Styled from './petroleumTypeStyles';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import RadioField from '../../common/formSections/radioField/radioField';
import creditApplication from '../../constants/creditApplication';
import { updateCreditApplication } from '../../store/action/action';

const PetroleumType = () => {
  const [translation] = useTranslation();
  const dispatch = useDispatch();
  const getWebData = useSelector((state) => state.websiteDataReducer.stateData);
  const color = getWebData.appColor;
  const getStoreData = useSelector((state) => state.Reducer.stateData);
  const petroleumType = [
    {
      label: translation('USLD'),
      value: getStoreData?.petroleumType?.includes(translation('USLD'))
        ? true
        : false,
    },
    {
      label: translation('MarkedDiesel'),
      value: getStoreData?.petroleumType?.includes(translation('MarkedDiesel'))
        ? true
        : false,
    },
    {
      label: translation('BIO'),
      value: getStoreData?.petroleumType?.includes(translation('BIO'))
        ? true
        : false,
    },
  ];

  const creditCopyObject = Object.assign(
    {},
    getStoreData ? getStoreData : creditApplication
  );
  const updateFields = (key, value) => {
    creditCopyObject[key] = value;
    dispatch(updateCreditApplication(creditCopyObject));
  };
  return (
    <>
      <Styled.SubHeader color={color}>
        {translation('PetroleumType')}
      </Styled.SubHeader>
      <RadioField
        radioData={petroleumType}
        name="petroleumType"
        color={color}
        width="4"
        handleRadioButton={(name, value) => updateFields(name, value)}
      />
    </>
  );
};
const mapStateToProps = (stateData) => {
  return {
    state: stateData,
  };
};

export default connect(mapStateToProps)(PetroleumType);
