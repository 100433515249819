import * as React from 'react';
import { Helmet } from 'react-helmet';
import { I18nextProvider } from 'react-i18next';
import i18next from '../i18next';
import { Provider } from 'react-redux';
import configureStore from '../store';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePageTemplate from '../templates/homePageTemplate/homePageTemplate';
import ThankYou from '../components/thankYou/thankYou';

const RootIndex = () => {
  const { store, persistor } = configureStore({});
  return (
    <>
      <I18nextProvider i18n={i18next}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Parkland Credit</title>
        </Helmet>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<HomePageTemplate />} />
                <Route path="/thankYou" element={<ThankYou />} />
              </Routes>
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </I18nextProvider>
    </>
  );
};

export default RootIndex;
