import styled from 'styled-components';

export const LogoContainer = styled.div`
  width: 100%;
  text-align: center;
  display: block;
  margin: 0;
  border-bottom: 1px dashed lightgray;
  padding: 24px;
`;

export const LogoImage = styled.img`
  width: 50%;
`;

export const LogoTitle = styled.h3`
  font-family: Arial;
  font-size: 14px;
  text-align: center;
  margin-top: 12px;
  color: #505050;
`;
