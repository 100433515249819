import React, { useState } from 'react';
import * as Styled from './taxExemptionStyles';
import { useTranslation } from 'react-i18next';
import MultiTypeFields from '../../common/formSections/multiTypeFields/multiTypeFields';
import TextField from '../../common/formSections/textField/textField';
import { connect, useDispatch, useSelector } from 'react-redux';
import creditApplication from '../../constants/creditApplication';
import { updateCreditApplication } from '../../store/action/action';
import { updateError } from '../../store/action/errorAction';
import errorData from '../../constants/errorData';

const TaxExemptions = () => {
  const [translation] = useTranslation();
  const dispatch = useDispatch();
  const getStoreData = useSelector((state) => state.Reducer.stateData);
  const errorStoreData = useSelector((state) => state.ErrorReducer.errorData);
  const getWebData = useSelector((state) => state.websiteDataReducer.stateData);
  const color = getWebData.appColor;
  const multiTypeFieldData = [
    {
      label: translation('NativeExemptions'),
      type: 'text',
      name: 'exceptionStatus',
      value: getStoreData?.taxExemptions?.exceptionStatus,
    },
    {
      label: translation('OffRoadExemption'),
      type: 'text',
      name: 'offRoad',
      value: getStoreData?.taxExemptions?.offRoad,
    },
    {
      label: translation('CommercialFishing'),
      type: 'text',
      name: 'fishingException',
      value: getStoreData?.taxExemptions?.fishingException,
    },
  ];
  const [other, setOther] = useState(getStoreData?.taxExemptions?.other);
  const [exceptionStatus, setExceptionStatus] = useState(
    getStoreData?.taxExemptions?.exceptionStatus
  );
  const [offRoad, setOffRoad] = useState(getStoreData?.taxExemptions?.offRoad);
  const [fishingException, setFishingException] = useState(
    getStoreData?.taxExemptions?.fishingException
  );
  let creditCopyObject = Object.assign(
    {},
    getStoreData ? getStoreData : creditApplication
  );
  let errorCopyObject = Object.assign(
    {},
    errorStoreData ? errorStoreData : errorData
  );
  const updateFields = (key, value) => {
    creditCopyObject['taxExemptions'] = {
      ...creditCopyObject['taxExemptions'],
      [key]: value,
    };
    dispatch(updateCreditApplication(creditCopyObject));
  };
  const updateErrorFields = (key, value) => {
    errorCopyObject = {
      ...errorCopyObject,
      [key]: value,
    };
    dispatch(updateError(errorCopyObject));
  };
  return (
    <>
      <Styled.SubHeader color={color}>
        {translation('TaxExemptions')}
      </Styled.SubHeader>
      <MultiTypeFields
        multiTypeFieldData={multiTypeFieldData}
        minHeight
        color={color}
        handleMultiType={(name, value) => {
          updateFields(name, value);
          name === 'exceptionStatus'
            ? setExceptionStatus(value)
            : name === 'offRoad'
            ? setOffRoad(value)
            : setFishingException(value);
        }}
        handleError={(name, value) => {
          updateErrorFields(name, value);
        }}
      />
      <TextField
        value={other}
        label={translation('OtherFrench')}
        name="other"
        color={color}
        handleTextInput={(name, value) => {
          updateFields(name, value);
          setOther(value);
        }}
      />
    </>
  );
};
const mapStateToProps = (stateData) => {
  return {
    state: stateData,
  };
};

export default connect(mapStateToProps)(TaxExemptions);
