import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import { AppColors } from '../../constants';
export const HeaderLayout = styled.h3`
  font-family: 'Arial';
  color: ${(props) => props.color};
  font-size: 21px;
  margin: 16px 0 16px 0;
  font-weight: bold;
`;

export const ParaText = styled.p`
  font-family: 'Arial';
  color: ${(props) => props.color};
  font-size: 14px;
`;
export const SubText = styled(ParaText)`
  color: ${AppColors.Gray};
  font-weight: bold;
  font-style: ${(props) => props.italic};
`;
export const Header = styled.h1`
  font-family: 'Arial';
  color: ${(props) => props.color};
  font-size: 16px;
`;
export const Header2 = styled.h3`
  font-family: 'Arial';
  color: ${(props) => props.color};
  font-size: 16px;
`;
export const Header3 = styled.h3`
  font-family: 'Arial';
  color: ${(props) => props.color};
  font-size: 14px;
  font-weight: bold;
`;

export const TermsContainer = styled.div`
  width: 100%;
  height: 350px;
  padding-right: 12px;
  text-align: justify;
  overflow: scroll;
  overflow-x: hidden;
`;

export const RowStyle = styled(Row)`
  margin: 0 auto !important;
`;
export const ColStyle = styled(Col)`
  padding-right: 1px !important;
  padding-left: 0 !important;
`;

export const Label = styled.label`
  color: ${(props) => props.color};
  background: ${AppColors.Gray93};
  display: block;
  width: 100%;
  min-height: 50px;
  margin-bottom: 1px;
  padding: 20px;
  font-size: 15px;
  line-height: 15px;
  text-align: left;
  clear: both;
  font-family: Arial;
  font-weight: bold;
  margin-top: 15px;
`;

export const InputField = styled.input`
  padding: 0;
  height: auto;
  border: none;
  width: auto;
  float: left;
  margin-right: 12px;
  margin-bottom: 2px;
  color: ${AppColors.Gray};
  background-color: white;
`;

export const NoteContainer = styled.div`
  margin-top: 10px;
`;
export const HorizontalBreak = styled.hr`
  border: none;
  border-top: 1px dashed #dedede;
  margin: 24px 0;
`;
export const ListText = styled.li`
  color: ${(props) => props.color};
  list-style: lower-alpha;
  text-decoration: none;
  font-size: 14px;
  font-family: 'Arial';
  letter-spacing: 0.02em;
  li {
    list-style: lower-roman;
  }
`;
