import styled from 'styled-components';

export const HeaderLayout = styled.h3`
  font-family: 'Arial';
  color: ${(props) => props.color};
  font-size: 14px;
  margin: 16px 0 16px 0;
  font-weight: bold;
`;

export const ParaText = styled.p`
  font-family: 'Arial';
  color: ${(props) => props.color};
  font-size: 12px;
`;
export const Header = styled.h1`
  font-family: 'Arial';
  color: ${(props) => props.color};
  font-size: 16px;
`;
export const Header2 = styled.h3`
  font-family: 'Arial';
  color: ${(props) => props.color};
  font-size: 16px;
`;

export const LinkStyle = styled.a`
  font-family: 'Arial';
  color: ${(props) => props.color};
  font-size: 12px;
`;
export const HorizontalBreak = styled.hr`
  border: none;
  border-top: 1px dashed #dedede;
  margin: 24px 0;
`;
