import React from 'react';
import * as Styled from './privacyNoticeStyles';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { useSelector } from 'react-redux';

const PrivacyNotice = ({ PrivacyNotice }) => {
  const getWebData = useSelector((state) => state.websiteDataReducer.stateData);
  const color = getWebData.appColor;
  const language = getWebData.language;
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaText color="#505050">{children}</Styled.ParaText>
      ),
      [BLOCKS.HEADING_1]: (node, children) => (
        <Styled.Header color="#505050">{children}</Styled.Header>
      ),
      [INLINES.HYPERLINK]: (node, children) =>
        language === 'en-US' ? (
          <Styled.LinkStyle color={color} href={node.data.uri} target="_blank">
            {children}
          </Styled.LinkStyle>
        ) : (
          <Styled.LinkStyle color={color} href={node.data.uri}>
            {children}
          </Styled.LinkStyle>
        ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
  };
  const content = PrivacyNotice.node.sections.filter(
    (section) => section.type === 'richTextWithParagraph'
  )[0];
  return (
    <>
      <Styled.HeaderLayout color={color}>{content.title}</Styled.HeaderLayout>
      {content &&
        documentToReactComponents(
          JSON.parse(content?.contentDetails?.raw),
          optionsMainStyle
        )}
      <Styled.HorizontalBreak />
    </>
  );
};
export default PrivacyNotice;
