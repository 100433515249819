import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AppColors } from '../../../constants';
import { isNotNull } from '../../../utils/validations';
import * as Styled from './radioFieldStyle';

const RadioField = ({
  radioData,
  name,
  color,
  handleRadioButton,
  width,
  value,
  handleError,
}) => {
  const [field, setField] = useState(value || '');
  const getWebData = useSelector((state) => state.websiteDataReducer.stateData);
  return (
    <Styled.RowStyle>
      {radioData.map((item, index) => (
        <Styled.ColStyle xs={width} key={index}>
          <Styled.Label
            color={
              item.required && getWebData.isSubmitted && !isNotNull(field)
                ? AppColors.Red
                : color
            }
          >
            {item.required ? '* ' + item.label : item.label}
            <Styled.InputField
              defaultChecked={item.value}
              type="radio"
              name={name}
              onChange={(e) => {
                handleRadioButton(name, item.label);
                setField(e.target.value);
                handleError?.(name, '');
              }}
              onBlur={(e) => {}}
            />
          </Styled.Label>
        </Styled.ColStyle>
      ))}
    </Styled.RowStyle>
  );
};

export default RadioField;
