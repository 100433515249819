import React, { useEffect, useState } from 'react';
import * as Styled from '../../styles';
import {
  GeneralInformation,
  Header,
  Logo,
  PrivacyNotice,
  TermsAndCondition,
  ApplicantType,
  ApplicantsYearEnd,
  AmendmentDate,
  AccountPayableContacts,
  AccountRequirements,
  BillingAddress,
  Captcha,
  ContactDetails,
  DeliveryAddress,
  EBilling,
  BankInformation,
  OnsiteRefuelingServiceInformation,
  PartnersPrincipalsOfficers,
  PetroleumProductsAccountInfo,
  PetroleumType,
  RequiredCardlockProducts,
  RequiredPetroleumProducts,
  Scheduling,
  TaxExemption,
  TradeReferences,
  TypesOfAccounts,
  Loader,
} from '../../components/components';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import InvalidDetails from '../../components/invalidDetails/invalidDetails';
import { connect, useDispatch, useSelector } from 'react-redux';
import { updateWebsiteData } from '../../store/action/websiteDataAction';
import creditApplication from '../../constants/creditApplication';
import { updateCreditApplication } from '../../store/action/action';
import { useTranslation } from 'react-i18next';

const HomePageTemplate = () => {
  const [translation] = useTranslation();
  const dispatch = useDispatch();
  const getWebData = useSelector((state) => state.websiteDataReducer.stateData);
  const getStoreData = useSelector((state) => state.Reducer.stateData);
  const [loading, setLoading] = useState(true);
  const data = useStaticQuery(graphql`
    query pageQuery {
      termsAndConditionsData: allContentfulGeneralPageTermsAndConditions {
        edges {
          node {
            slug
            node_locale
            name
            title
            type {
              ... on ContentfulCreditParklandIdentifier {
                type
              }
            }
            sections {
              ... on ContentfulHeaderWithText {
                type
                title
                headerText
                text {
                  text
                }
              }
              ... on ContentfulContentList {
                title
                id
                type
                list {
                  title
                  type
                  header
                  contentDetails {
                    raw
                  }
                }
              }
              ... on ContentfulContent {
                id
                type
                title
                contentDetails {
                  raw
                }
              }
            }
          }
        }
      }
      allContentfulBrands {
        edges {
          node {
            node_locale
            id
            brand
            creditColour
            creditLogo {
              file {
                url
                details {
                  image {
                    width
                    height
                  }
                }
              }
            }
            creditText {
              creditText
            }
            creditBranch
            creditResidentialRedirect
          }
        }
      }
    }
  `);
  const details = data?.allContentfulBrands?.edges?.map((node) => {
    return node?.node.node_locale;
  });
  const frenchLanguageList = ['fr-CA', 'fr'];
  const searchParam = window.location.search;
  const params = new URLSearchParams(searchParam);
  const companyParam = params.get('company')
    ? params.get('company').toLowerCase()
    : '';
  const typeParam = params?.get('type');
  const language = frenchLanguageList.includes(params?.get('lang'))
    ? 'fr-CA'
    : 'en-US';
  const companyDetailsList = data?.allContentfulBrands?.edges.filter((node) => {
    return node?.node?.node_locale === language;
  });
  const termsAndConditions = data?.termsAndConditionsData?.edges.filter(
    (node) => {
      return node?.node?.node_locale === language;
    }
  )[0];
  const currentCompanyDetails = companyDetailsList.filter((node) => {
    return node?.node?.brand.toLowerCase() === companyParam;
  });
  const appColor = currentCompanyDetails[0]?.node?.creditColour;
  const closestBranch = currentCompanyDetails[0]?.node?.creditBranch;
  const residentialRedirect =
    currentCompanyDetails[0]?.node?.creditResidentialRedirect;
  const companyData = {
    companyName: companyParam,
    companyType: typeParam,
    appColor: appColor,
    language: language,
    isSubmitted: false,
  };
  useEffect(() => {
    if (typeParam === 'residential') {
      navigate(residentialRedirect);
    }
  }, [typeParam]);
  const isValidUrl = () => {
    const companyList = companyDetailsList.map((node) => node?.node?.brand);
    const isValidType = typeParam === 'commercial';
    const isValidCompany = companyList
      .map((element) => element.toLowerCase())
      .includes(companyParam);
    return isValidCompany && isValidType;
  };
  const creditLogo = currentCompanyDetails[0]?.node?.creditLogo?.file.url;
  const creditText = currentCompanyDetails[0]?.node?.creditText?.creditText;
  const creditCopyObject = Object.assign(
    {},
    getStoreData ? getStoreData : creditApplication
  );
  useEffect(() => {
    creditCopyObject['type'] = typeParam;
    creditCopyObject['organization'] = companyParam;
    creditCopyObject['language'] = language;
    creditCopyObject['billingMethod'] = translation('Yes');
    creditCopyObject['yearEnd']['financialStatements'] =
      translation('ApplicantYearEndNo');
    creditCopyObject['productsAccountInformation']['existingAccount'] =
      translation('ApplicantYearEndNo');
    creditCopyObject['accountRequirements']['statementRequired'] =
      translation('ApplicantYearEndNo');
    creditCopyObject['accountRequirements']['purchaseOrders'] =
      translation('ApplicantYearEndNo');
    dispatch(updateWebsiteData(companyData));
    dispatch(updateCreditApplication(creditCopyObject));
  }, [companyParam, typeParam, language]);

  if (
    companyParam &&
    typeParam &&
    localStorage.persist &&
    ((getWebData.companyName && getWebData.companyName !== companyParam) ||
      (getWebData.companyType && getWebData.companyType !== typeParam))
  ) {
    localStorage.removeItem('persist:root');
    if (typeof window !== 'undefined') {
      window.location.reload();
    }
  }
  if (typeof window !== 'undefined') {
    window.onbeforeunload = () => {
      localStorage.clear();
    };
  }
  return isValidUrl() && getWebData.language ? (
    loading ? (
      <div>
        <Styled.PageContainer>
          <Logo creditLogo={creditLogo} creditText={creditText} />
          <Header />
          <GeneralInformation closestBranchList={closestBranch} />
          <ApplicantType />
          <EBilling />
          <BillingAddress />
          <DeliveryAddress />
          <ContactDetails />
          <ApplicantsYearEnd />
          <AccountPayableContacts />
          <PartnersPrincipalsOfficers />
          <TradeReferences />
          <BankInformation />
          <PetroleumProductsAccountInfo />
          <TypesOfAccounts />
          <RequiredPetroleumProducts />
          <RequiredCardlockProducts />
          <OnsiteRefuelingServiceInformation />
          <PetroleumType />
          <Scheduling />
          <TaxExemption />
          <AccountRequirements />
          <TermsAndCondition termsAndConditions={termsAndConditions} />
          <Captcha />
          <PrivacyNotice PrivacyNotice={termsAndConditions} />
          <AmendmentDate setLoading={setLoading} />
        </Styled.PageContainer>
      </div>
    ) : (
      <Loader />
    )
  ) : typeParam !== 'residential' && !isValidUrl() ? (
    <InvalidDetails />
  ) : null;
};

const mapStateToProps = (stateData) => {
  return {
    state: stateData,
  };
};

export default connect(mapStateToProps)(HomePageTemplate);
