export const Breakpoints = {
  ScreenXS: '374px',
  ScreenSM: '750px',
  ScreenMD: '992px',
  ScreenLG: '1200px',
  ScreenXL: '1600px',
};

export const organizationTypes = ['commercial', 'residential'];

export const AppColors = {
  Red: '#FF0000',
  Gray93: '#ededed',
  White: '#FFFFFF',
  Gray: '#505050',
  LightPink: '#FCDFE0',
  LightGray: '#808080',
};
