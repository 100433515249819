import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';

export const RowStyle = styled(Row)`
  margin: 0 auto !important;
`;
export const ColStyle = styled(Col)`
  padding-right: 1px !important;
  padding-left: 0 !important;
`;
export const HeaderLayout = styled.h2`
  font-family: 'Arial';
  color: ${(props) => props.color};
  font-size: 21px;
  margin: 16px 0 16px 0;
  font-weight: bold;
`;
