import React, { useState } from 'react';
import * as Styled from './captchaStyles';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { updateCreditApplication } from '../../store/action/action';
import creditApplication from '../../constants/creditApplication';
import { isNotNull } from '../../utils/validations';
import errorData from '../../constants/errorData';
import { updateError } from '../../store/action/errorAction';

const Captcha = () => {
  const getWebData = useSelector((state) => state.websiteDataReducer.stateData);
  const getStoreData = useSelector((state) => state.Reducer.stateData);
  const dispatch = useDispatch();
  const color = getWebData.appColor;
  const language = getWebData.language;
  const [translation] = useTranslation();
  const [captchaValue, setCaptchaValue] = useState(
    getStoreData?.verificationCode
  );
  const creditCopyObject = Object.assign(
    {},
    getStoreData ? getStoreData : creditApplication
  );
  const sitekey = `${process.env.GATSBY_GOOGLE_CAPTCHA_SITEKEY}`;
  const updateFields = (key, value) => {
    creditCopyObject[key] = value;
    dispatch(updateCreditApplication(creditCopyObject));
  };
  const errorStoreData = useSelector((state) => state.ErrorReducer.errorData);
  let errorCopyObject = Object.assign(
    {},
    errorStoreData ? errorStoreData : errorData
  );
  const updateErrorFields = (key, value) => {
    errorCopyObject = {
      ...errorCopyObject,
      [key]: value,
    };
    dispatch(updateError(errorCopyObject));
  };
  const styleChange = getWebData.isSubmitted && !isNotNull(captchaValue);
  return (
    <>
      <Styled.HeaderLayout color={color}>
        {translation('VerificationCode')}
      </Styled.HeaderLayout>
      <Styled.CaptchaContainer color={'#ededed'}>
        <Styled.RecaptchaContainer
          $borderColor={styleChange}
          hl={language}
          sitekey={sitekey}
          onChange={(value) => {
            setCaptchaValue(value);
            updateFields('verificationCode', value);
            updateErrorFields('verificationCode', '');
          }}
          onExpired={() => {
            setCaptchaValue('');
            updateFields('verificationCode', '');
            updateErrorFields(
              'verificationCode',
              translation('VerificationCode') + ' required'
            );
          }}
        />
      </Styled.CaptchaContainer>
    </>
  );
};
const mapStateToProps = (stateData) => {
  return {
    state: stateData,
  };
};

export default connect(mapStateToProps)(Captcha);
