import React, { useRef, useState } from 'react';
import * as Styled from './textAreaFieldStyle';
import { isNotNull, validateForm } from '../../../utils/validations';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AppColors } from '../../../constants';

const TextAreaField = ({
  label,
  name,
  type,
  color,
  required,
  handleTextInput,
  value,
  handleTextError,
}) => {
  const [translation] = useTranslation();
  const errorStoreData = useSelector((state) => state.ErrorReducer.errorData);
  const getWebData = useSelector((state) => state.websiteDataReducer.stateData);
  const textAreaInput = useRef(null);
  const [field, setField] = useState(value || '');
  const [errorMessage, setErrorMessage] = useState('');
  const updateErrorMessage = (value) => {
    setErrorMessage(value);
  };
  const inputColor =
    required && getWebData.isSubmitted && !isNotNull(field)
      ? AppColors.Red
      : required &&
        getWebData.isSubmitted &&
        (errorStoreData[name] !== '' || errorStoreData[name] === undefined) &&
        isNotNull(field)
      ? AppColors.Red
      : color;

  const inputFieldColor =
    (required && getWebData.isSubmitted && !isNotNull(field)) ||
    (required &&
      getWebData.isSubmitted &&
      (errorStoreData[name] !== '' || errorStoreData[name] === undefined) &&
      isNotNull(field));
  return (
    <>
      <Styled.InputContainer color={inputColor}>
        {required ? '* ' + label : label}
        <Styled.InputField
          $borderColor={inputFieldColor}
          $background={inputFieldColor}
          value={field}
          ref={textAreaInput}
          type="textarea"
          onChange={(e) => setField(e.target.value)}
          onBlur={(e) => {
            validateForm(
              name,
              type,
              e.target,
              textAreaInput,
              handleTextError,
              required,
              label,
              updateErrorMessage,
              translation
            );
            handleTextInput(name, e.target.value);
            setField(e.target.value);
          }}
        />
      </Styled.InputContainer>
      <Styled.ErrorContainer>{errorMessage}</Styled.ErrorContainer>
    </>
  );
};

const mapStateToProps = (stateData) => {
  return {
    state: stateData,
  };
};
export default connect(mapStateToProps)(TextAreaField);
