import styled from 'styled-components';
import { AppColors } from '../../constants';

export const SubHeader = styled.h3`
  color: ${(props) => props.color};
  margin: 16px 0 16px 0;
  font-size: 14px;
  font-weight: bold;
  font-family: 'Arial';
`;

export const HorizontalBreak = styled.hr`
  border: none;
  border-top: 1px dashed ${AppColors.Gray93};
  margin: 24px 0;
  background-color: ${AppColors.White} !important;
  opacity: 1 !important;
`;
