import React from 'react';
import * as Styled from './typesOfAccountsStyles';
import { useTranslation } from 'react-i18next';
import CheckBoxField from '../../common/formSections/checkBoxField/checkBoxField';
import { connect, useDispatch, useSelector } from 'react-redux';
import creditApplication from '../../constants/creditApplication';
import { updateCreditApplication } from '../../store/action/action';
import { updateError } from '../../store/action/errorAction';
import errorData from '../../constants/errorData';

const TypeOfAccounts = () => {
  const [translation] = useTranslation();
  const dispatch = useDispatch();
  const getStoreData = useSelector((state) => state.Reducer.stateData);
  const errorStoreData = useSelector((state) => state.ErrorReducer.errorData);
  const getWebData = useSelector((state) => state.websiteDataReducer.stateData);
  const color = getWebData.appColor;
  const checkBoxData = [
    {
      label: translation('DirectDelivery'),
      name: 'directDelivery',
      required: 'required',
      value: getStoreData?.typesOfAccount?.directDelivery,
    },
    {
      label: translation('WillCall'),
      name: 'willCall',
      required: 'required',
      value: getStoreData?.typesOfAccount?.willCall,
    },
    {
      label: translation('CustomerPickUp'),
      name: 'pickup',
      required: 'required',
      value: getStoreData?.typesOfAccount?.pickup,
    },
    {
      label: translation('Cardlock'),
      name: 'cardlock',
      required: 'required',
      value: getStoreData?.typesOfAccount?.cardlock,
    },
    {
      label: translation('OnsiteRefueling'),
      name: 'onsiteRefueling',
      required: 'required',
      value: getStoreData?.typesOfAccount?.onsiteRefueling,
    },
  ];
  let creditCopyObject = Object.assign(
    {},
    getStoreData ? getStoreData : creditApplication
  );
  let errorCopyObject = Object.assign(
    {},
    errorStoreData ? errorStoreData : errorData
  );
  const updateFields = (key, value) => {
    creditCopyObject['typesOfAccount'] = {
      ...creditCopyObject['typesOfAccount'],
      [key]: value,
    };
    dispatch(updateCreditApplication(creditCopyObject));
  };
  const updateErrorFields = (key, value) => {
    errorCopyObject = {
      ...errorCopyObject,
      [key]: value,
    };
    dispatch(updateError(errorCopyObject));
  };
  return (
    <>
      <Styled.SubHeader color={color}>
        {translation('TypeOfAccount')}
      </Styled.SubHeader>
      <CheckBoxField
        checkBoxData={checkBoxData}
        color={color}
        handleCheckBox={(name, value) => updateFields(name, value)}
        handleError={(name, value) => {
          updateErrorFields(name, value);
        }}
        width="12"
      />
    </>
  );
};
const mapStateToProps = (stateData) => {
  return {
    state: stateData,
  };
};

export default connect(mapStateToProps)(TypeOfAccounts);
