import styled from 'styled-components';
import { AppColors } from '../../constants';

export const HorizontalBreak = styled.hr`
  border: none;
  border-top: 1px dashed ${AppColors.Gray93};
  margin: 24px 0;
  background-color: ${AppColors.White} !important;
  opacity: 1 !important;
`;
