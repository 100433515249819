import React, { useRef, useState } from 'react';
import * as Styled from './onsiteRefuelingServiceInformationStyles';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { updateCreditApplication } from '../../store/action/action';
import creditApplication from '../../constants/creditApplication';
import TextField from '../../common/formSections/textField/textField';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';
import 'moment/min/locales.min';
import { updateError } from '../../store/action/errorAction';
import errorData from '../../constants/errorData';
import { isNotNull, validateForm } from '../../utils/validations';
import { AppColors } from '../../constants';

const OnsiteRefuelingService = () => {
  const [translation] = useTranslation();
  const dispatch = useDispatch();

  const getWebData = useSelector((state) => state.websiteDataReducer.stateData);
  const color = getWebData.appColor;
  const language = getWebData.language;
  const getStoreData = useSelector((state) => state.Reducer.stateData);
  const errorStoreData = useSelector((state) => state.ErrorReducer.errorData);
  const [errorMessage, setErrorMessage] = useState('');
  const updateErrorMessage = (value) => {
    setErrorMessage(value);
  };
  const [noOfTrucks, setNoOfTrucks] = useState(
    getStoreData.refuelingServiceInformation.noOfTrucks
  );
  const [additionalEquipment, setAdditionalEquipment] = useState(
    getStoreData.refuelingServiceInformation.additionalEquipment
  );
  const [startDate, setStartDate] = useState(
    getStoreData.refuelingServiceInformation.serviceStartDate
  );
  let creditCopyObject = Object.assign(
    {},
    getStoreData ? getStoreData : creditApplication
  );
  let errorCopyObject = Object.assign(
    {},
    errorStoreData ? errorStoreData : errorData
  );
  const updateFields = (key, value) => {
    creditCopyObject['refuelingServiceInformation'] = {
      ...creditCopyObject['refuelingServiceInformation'],
      [key]: value,
    };
    dispatch(updateCreditApplication(creditCopyObject));
  };
  const updateErrorFields = (key, value) => {
    errorCopyObject = {
      ...errorCopyObject,
      [key]: value,
    };
    dispatch(updateError(errorCopyObject));
  };
  const dateInputRef = useRef(null);
  const dateColor =
    getWebData.isSubmitted && !isNotNull(startDate)
      ? AppColors.Red
      : getWebData.isSubmitted &&
        (errorStoreData['serviceStartDate'] !== '' ||
          errorStoreData['serviceStartDate'] === undefined) &&
        isNotNull(startDate)
      ? AppColors.Red
      : color;
  return (
    <>
      <Styled.SubHeader color={color}>
        {translation('OnsiteRefuelingService')}
      </Styled.SubHeader>
      <Styled.RowStyle>
        <Styled.ColStyle xs={4}>
          <TextField
            value={noOfTrucks}
            label={translation('NumberOfTrucks')}
            name="noOfTrucks"
            color={color}
            handleTextInput={(name, value) => {
              updateFields(name, value);
              setNoOfTrucks(value);
            }}
            handleTextError={(name, value) => {
              updateErrorFields(name, value);
            }}
          />
        </Styled.ColStyle>
        <Styled.ColStyle xs={4}>
          <TextField
            value={additionalEquipment}
            label={translation('AdditionalEquipment')}
            name="additionalEquipment"
            color={color}
            handleTextInput={(name, value) => {
              updateFields(name, value);
              setAdditionalEquipment(value);
            }}
            handleTextError={(name, value) => {
              updateErrorFields(name, value);
            }}
          />
        </Styled.ColStyle>
        <Styled.ColStyle xs={4}>
          <Styled.DateContainer color={dateColor}>
            {translation('ServiceStartDate')}
            <Styled.DatetimeStyle
              errorStoreData={errorStoreData}
              ref={dateInputRef}
              isSubmitted={getWebData.isSubmitted}
              value={startDate}
              timeFormat={false}
              dateFormat={'MMM. DD, YYYY'}
              closeOnSelect={true}
              locale={language}
              onClose={(e) => {
                updateFields(
                  'serviceStartDate',
                  moment(e).format('MMM. DD, YYYY')
                );
                setStartDate(moment(e).format('MMM. DD, YYYY'));
                validateForm(
                  'serviceStartDate',
                  'date',
                  moment(e).format('MMM. DD, YYYY'),
                  { dateInputRef },
                  updateErrorFields,
                  'required',
                  'Service Start Date',
                  updateErrorMessage,
                  translation
                );
              }}
            />
          </Styled.DateContainer>
          <Styled.ErrorContainer>{errorMessage}</Styled.ErrorContainer>
        </Styled.ColStyle>
      </Styled.RowStyle>
    </>
  );
};
const mapStateToProps = (stateData) => {
  return {
    state: stateData,
  };
};

export default connect(mapStateToProps)(OnsiteRefuelingService);
