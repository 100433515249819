import errorData from '../../constants/errorData';
import { ERROR_ACTION_NAMES } from '../action/errorAction';

const initialState = errorData;
export const ErrorReducer = (state = { errorData: initialState }, action) => {
  switch (action.type) {
    case ERROR_ACTION_NAMES.UPDATE_STATE_ERROR:
      return {
        errorData: action.payload,
      };
    default:
      return state;
  }
};
