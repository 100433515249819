import * as React from 'react';
import ContentLoader from 'react-content-loader';
const Loader = () => {
  return (
    <>
      <ContentLoader viewBox="0 0 380 380">
        {/* Only SVG shapes */}
        <rect x="150" y="17" rx="4" ry="4" width="100" height="20" />
        <rect x="150" y="40" rx="3" ry="3" width="100" height="5" />
        <rect x="125" y="60" rx="3" ry="3" width="150" height="10" />
        <rect x="125" y="75" rx="3" ry="3" width="50" height="3" />
        <rect x="125" y="80" rx="3" ry="3" width="60" height="15" />
        <rect x="125" y="100" rx="3" ry="3" width="80" height="8" />
        <rect x="125" y="115" rx="3" ry="3" width="150" height="80" />
        <rect x="125" y="200" rx="3" ry="3" width="50" height="5" />
        <rect x="125" y="210" rx="3" ry="3" width="150" height="50" />
        <rect x="125" y="270" rx="3" ry="3" width="150" height="70" />
      </ContentLoader>
    </>
  );
};
export default Loader;
