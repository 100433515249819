const errorData = {
  registeredCompanyName: false,
  gst: false,
  closestBranch: false,
  applicantIsA: false,
  dateApplicantWasEstablished: false,
  natureOfApplicantsBusiness: false,
  operatingUnderCurrentOwnership: false,
  emailAddress: false,
  streetAddress: false,
  city: false,
  province: false,
  billingAddressPostalCode: false,
  phone: false,
  financialYearEndDate: false,
  applicantsPartnersDateOfBirth1: false,
  applicantsPartnersDateOfBirth2: false,
  applicantsPartnersDateOfBirth3: false,
  tradeReferencesCompanyName1: false,
  tradeReferencesName1: false,
  tradeReferencesPhone1: false,
  tradeReferencesCompanyName2: false,
  tradeReferencesName2: false,
  tradeReferencesPhone2: false,
  tradeReferencesCompanyName3: false,
  tradeReferencesName3: false,
  tradeReferencesPhone3: false,
  bank: false,
  account: false,
  branch: false,
  bankInformationAddress: false,
  directDelivery: false,
  willCall: false,
  pickup: false,
  cardlock: false,
  onsiteRefueling: false,
  serviceStartDate: false,
  creditLimit: false,
  iAgree: false,
};
export default errorData;
