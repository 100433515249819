import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import Datetime from 'react-datetime';
import { isNotNull } from '../../utils/validations';
import { AppColors } from '../../constants';

export const RowStyle = styled(Row)`
  margin: 0 auto !important;
`;
export const ColStyle = styled(Col)`
  padding-right: 1px !important;
  padding-left: 0 !important;
`;

export const HeaderLayout = styled.h2`
  font-family: 'Arial';
  color: ${(props) => props.color};
  font-size: 21px;
  margin: 16px 0 16px 0;
  font-weight: bold;
`;

export const InputContainer = styled.label`
  background-color: ${AppColors.Gray93};
  color: ${(props) => props.color};
  display: block;
  width: 100%;
  min-height: 31px;
  margin-bottom: 1px;
  padding: 8px;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
  clear: both;
  font-family: Arial;
`;

export const DateContainer = styled.div`
  background-color: ${AppColors.Gray93};
  padding: 8px;
  display: block;
  clear: both;
  font-family: Arial;
  font-size: 12px;
  color: ${(props) => props.color};
`;

export const DatetimeStyle = styled(Datetime)`
  .form-control {
    color: ${(props) => props.color};
    width: 100%;
    height: 30px;
    font-family: Arial;
    font-size: 12px;
    color: ${AppColors.Gray};
    padding: 8px;
    border-radius: 0 !important;
    border: ${(props) =>
      props.isSubmitted && !isNotNull(props.value)
        ? '1px solid red'
        : props.isSubmitted &&
          (props.errorStoreData[props.index] !== '' ||
            props.errorStoreData[props.index] === undefined) &&
          isNotNull(props.value)
        ? '1px solid red'
        : '1px solid lightgray'};

    background-color: ${(props) =>
      props.isSubmitted && !isNotNull(props.value)
        ? AppColors.LightPink
        : props.isSubmitted &&
          (props.errorStoreData[props.index] !== '' ||
            props.errorStoreData[props.index] === undefined) &&
          isNotNull(props.value)
        ? AppColors.LightPink
        : AppColors.White};
  }
`;
