const RequiredFieldData = {
  generalInformation: {
    registeredCompanyName: '',
    gst: '',
    closestBranch: '',
    applicantIsA: '',
    dateApplicantWasEstablished: '',
    natureOfApplicantsBusiness: '',
    operatingUnderCurrentOwnership: '',
  },
  billingMethod: '',
  billingAddress: {
    emailAddress: '',
    streetAddress: '',
    city: '',
    province: '',
    billingAddressPostalCode: '',
  },
  contactInfo: {
    phone: '',
  },
  yearEnd: {
    financialStatements: '',
    financialYearEndDate: '',
  },

  applicantsPartners: {
    applicantsPartnersDateOfBirth1: '',
    applicantsPartnersDateOfBirth2: '',
    applicantsPartnersDateOfBirth3: '',
  },
  tradeReferences: {
    tradeReferencesCompanyName1: '',
    tradeReferencesName1: '',
    tradeReferencesPhone1: '',
    tradeReferencesCompanyName2: '',
    tradeReferencesName2: '',
    tradeReferencesPhone2: '',
    tradeReferencesCompanyName3: '',
    tradeReferencesName3: '',
    tradeReferencesPhone3: '',
  },
  bankInformation: {
    bank: '',
    account: '',
    branch: '',
    bankInformationAddress: '',
  },
  productsAccountInformation: {
    existingAccount: '',
  },
  typesOfAccount: {
    directDelivery: false,
    willCall: false,
    pickup: false,
    cardlock: false,
    onsiteRefueling: false,
  },
  refuelingServiceInformation: {
    serviceStartDate: '',
  },

  accountRequirements: {
    creditLimit: '',
  },
  iAgree: false,
  verificationCode: '',
};

export default RequiredFieldData;
