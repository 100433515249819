import styled from 'styled-components';
import { AppColors } from '../../constants';

export const SubHeader = styled.h3`
  color: ${(props) => props.color};
  margin: 16px 0 16px 0;
  font-size: 14px;
  font-weight: bold;
  font-family: 'Arial';
`;

export const NoteStyle = styled.div`
  font-style: italic;
  margin: 16px 0 16px 0;
  font-family: Arial;
  font-size: 14px;
  color: ${AppColors.Gray};
`;
export const Note = styled.div`
  display: inline;
  font-weight: bold;
  font-style: italic;
  text-transform: uppercase;
`;
