import ReCAPTCHA from 'react-google-recaptcha';
import styled from 'styled-components';
import { AppColors } from '../../constants';

export const HeaderLayout = styled.h2`
  font-family: 'Arial';
  color: ${(props) => props.color};
  font-size: 21px;
  margin: 16px 0 16px 0;
  font-weight: bold;
`;
export const CaptchaContainer = styled.div`
  background-color: ${AppColors.Gray93};
  padding: 8px;
  display: block;
  clear: both;
  font-family: Arial;
  font-size: 12px;
  margin: 1px 0px 0px 1px;
  color: ${AppColors.Gray};
`;
export const RecaptchaContainer = styled(ReCAPTCHA)`
  border: ${(props) => (props.$borderColor ? '1px solid red' : '')};
`;
