import React, { useState } from 'react';
import * as Styled from './requiredPetroleumProductsStyles';
import { useTranslation } from 'react-i18next';
import CheckBoxField from '../../common/formSections/checkBoxField/checkBoxField';
import TextField from '../../common/formSections/textField/textField';
import { connect, useDispatch, useSelector } from 'react-redux';
import creditApplication from '../../constants/creditApplication';
import { updateCreditApplication } from '../../store/action/action';
import errorData from '../../constants/errorData';
import { updateError } from '../../store/action/errorAction';

const RequiredPetroleumProducts = () => {
  const [translation] = useTranslation();
  const dispatch = useDispatch();
  const getStoreData = useSelector((state) => state.Reducer.stateData);
  const errorStoreData = useSelector((state) => state.ErrorReducer.errorData);
  const getWebData = useSelector((state) => state.websiteDataReducer.stateData);
  const color = getWebData.appColor;
  const checkBoxData = [
    {
      label: translation('Diesel'),
      name: 'diesel',
      value: getStoreData?.requiredPetroleumProducts?.diesel,
    },
    {
      label: translation('MarkedDiesel'),
      name: 'markedDiesel',
      value: getStoreData?.requiredPetroleumProducts?.markedDiesel,
    },
    {
      label: translation('Gas'),
      name: 'gas',
      value: getStoreData?.requiredPetroleumProducts?.gas,
    },
    {
      label: translation('MarkedGas'),
      name: 'markedGas',
      value: getStoreData?.requiredPetroleumProducts?.markedGas,
    },
    {
      label: translation('Propane'),
      name: 'propane',
      value: getStoreData?.requiredPetroleumProducts?.propane,
    },
    {
      label: translation('BioDiesel'),
      name: 'bioDiesel',
      value: getStoreData?.requiredPetroleumProducts?.bioDiesel,
    },
    {
      label: translation('AgriculturalProducts'),
      name: 'agriculturalProducts',
      value: getStoreData?.requiredPetroleumProducts?.agriculturalProducts,
    },
    {
      label: translation('NaturalGas'),
      name: 'naturalGas',
      value: getStoreData?.requiredPetroleumProducts?.naturalGas,
    },
    {
      label: translation('HeatingOil'),
      name: 'heatingOil',
      value: getStoreData?.requiredPetroleumProducts?.heatingOil,
    },
    {
      label: translation('Lubricants'),
      name: 'lubricants',
      value: getStoreData?.requiredPetroleumProducts?.lubricants,
    },
    {
      label: translation('Marine'),
      name: 'marine',
      value: getStoreData?.requiredPetroleumProducts?.marine,
    },
    {
      label: translation('Aviation'),
      name: 'aviation',
      value: getStoreData?.requiredPetroleumProducts?.aviation,
    },
  ];
  const [other, setOther] = useState(
    getStoreData.requiredPetroleumProducts.other
  );
  let creditCopyObject = Object.assign(
    {},
    getStoreData ? getStoreData : creditApplication
  );
  let errorCopyObject = Object.assign(
    {},
    errorStoreData ? errorStoreData : errorData
  );
  const updateFields = (key, value) => {
    creditCopyObject['requiredPetroleumProducts'] = {
      ...creditCopyObject['requiredPetroleumProducts'],
      [key]: value,
    };
    dispatch(updateCreditApplication(creditCopyObject));
  };
  const updateTextFields = (key, value) => {
    creditCopyObject = {
      ...creditCopyObject,
      [key]: value,
    };
    dispatch(updateCreditApplication(creditCopyObject));
  };
  const updateErrorFields = (key, value) => {
    errorCopyObject = {
      ...errorCopyObject,
      [key]: value,
    };
    dispatch(updateError(errorCopyObject));
  };
  return (
    <>
      <Styled.SubHeader color={color}>
        {translation('RequiredPetroleumProducts')}
      </Styled.SubHeader>
      <CheckBoxField
        checkBoxData={checkBoxData}
        color={color}
        width="3"
        handleCheckBox={(name, value) => updateFields(name, value)}
      />
      <TextField
        value={other}
        label={translation('OtherFrench')}
        name="requiredPetroleumProductsOther"
        color={color}
        handleTextInput={(name, value) => {
          updateTextFields(name, value);
          setOther(value);
        }}
        handleTextError={(name, value) => {
          updateErrorFields(name, value);
        }}
      />
    </>
  );
};
const mapStateToProps = (stateData) => {
  return {
    state: stateData,
  };
};

export default connect(mapStateToProps)(RequiredPetroleumProducts);
