const labelData = {
  registeredCompanyName: 'LegalCompanyName',
  gst: 'GST',
  closestBranch: 'ClosestBranch',
  applicantIsA: 'ApplicantIsA',
  dateApplicantWasEstablished: 'DateApplicantEstablished',
  natureOfApplicantsBusiness: 'NatureOfBusiness',
  operatingUnderCurrentOwnership: 'YearsApplicantOperating',
  billingMethod: 'EBilling',
  emailAddress: 'EmailAddress',
  streetAddress: 'StreetAddress',
  city: 'City',
  province: 'Province',
  billingAddressPostalCode: 'PostalCode',
  phone: 'Phone',
  financialYearEndDate: 'FinancialYearEnd',
  applicantsPartnersDateOfBirth1: 'DOB1',
  applicantsPartnersDateOfBirth2: 'DOB2',
  applicantsPartnersDateOfBirth3: 'DOB3',
  tradeReferencesCompanyName1: 'CompanyName1',
  tradeReferencesName1: 'Name1',
  tradeReferencesPhone1: 'Phone1',
  tradeReferencesCompanyName2: 'CompanyName2',
  tradeReferencesName2: 'Name2',
  tradeReferencesPhone2: 'Phone2',
  tradeReferencesCompanyName3: 'CompanyName3',
  tradeReferencesName3: 'Name3',
  tradeReferencesPhone3: 'Phone3',
  bank: 'Bank',
  account: 'Account',
  branch: 'Branch',
  bankInformationAddress: 'Address',
  directDelivery: 'DirectDelivery',
  willCall: 'WillCall',
  pickup: 'CustomerPickUp',
  cardlock: 'Cardlock',
  onsiteRefueling: 'OnsiteRefueling',
  serviceStartDate: 'ServiceStartDate',
  creditLimit: 'CreditLimit',
  iAgree: 'IAccept',
  verificationCode: 'VerificationCode',
};

export default labelData;
