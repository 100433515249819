import React, { useState } from 'react';
import * as Styled from './accountRequirementsStyles';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { updateCreditApplication } from '../../store/action/action';
import creditApplication from '../../constants/creditApplication';
import TextField from '../../common/formSections/textField/textField';
import TextAreaField from '../../common/formSections/textAreaField/textAreaField';
import { updateError } from '../../store/action/errorAction';
import errorData from '../../constants/errorData';
import RadioField from '../../common/formSections/radioField/radioField';

const AccountRequirements = () => {
  const [translation] = useTranslation();
  const dispatch = useDispatch();
  const getStoreData = useSelector((state) => state.Reducer.stateData);
  const errorStoreData = useSelector((state) => state.ErrorReducer.errorData);
  const getWebData = useSelector((state) => state.websiteDataReducer.stateData);
  const color = getWebData.appColor;
  const [creditLimit, setCreditLimit] = useState(
    getStoreData?.accountRequirements?.creditLimit
  );
  const [otherSpecifications, setOtherSpecifications] = useState(
    getStoreData?.accountRequirements?.otherSpecifications
  );
  const radioData = [
    {
      label: translation('ApplicantYearEndYes'),
      value: getStoreData?.accountRequirements?.statementRequired.includes(
        translation('ApplicantYearEndYes')
      )
        ? true
        : false,
    },
    {
      label: translation('ApplicantYearEndNo'),
      value: getStoreData?.accountRequirements?.statementRequired.includes(
        translation('ApplicantYearEndNo')
      )
        ? true
        : false,
    },
  ];
  const purchaseRadioData = [
    {
      label: translation('ApplicantYearEndYes'),
      value: getStoreData?.accountRequirements?.purchaseOrders.includes(
        translation('ApplicantYearEndYes')
      )
        ? true
        : false,
    },
    {
      label: translation('ApplicantYearEndNo'),
      value: getStoreData?.accountRequirements?.purchaseOrders.includes(
        translation('ApplicantYearEndNo')
      )
        ? true
        : false,
    },
  ];
  let creditCopyObject = Object.assign(
    {},
    getStoreData ? getStoreData : creditApplication
  );
  let errorCopyObject = Object.assign(
    {},
    errorStoreData ? errorStoreData : errorData
  );
  const updateFields = (key, value) => {
    creditCopyObject['accountRequirements'] = {
      ...creditCopyObject['accountRequirements'],
      [key]: value,
    };
    dispatch(updateCreditApplication(creditCopyObject));
  };
  const updateErrorFields = (key, value) => {
    errorCopyObject = {
      ...errorCopyObject,
      [key]: value,
    };
    dispatch(updateError(errorCopyObject));
  };
  return (
    <>
      <Styled.HeaderLayout color={color}>
        {translation('AccountRequirements')}
      </Styled.HeaderLayout>
      <Styled.RowStyle>
        <Styled.ColStyle xs={6}>
          <TextField
            value={creditLimit}
            label={translation('CreditLimit')}
            name="creditLimit"
            type="text"
            color={color}
            required="required"
            handleTextInput={(name, value) => {
              updateFields(name, value);
              setCreditLimit(value);
            }}
            handleTextError={(name, value) => {
              updateErrorFields(name, value);
            }}
          />
        </Styled.ColStyle>
        <Styled.ColStyle xs={6}>
          <Styled.InputContainer color={color}>
            {translation('StatementRequired')}
          </Styled.InputContainer>
          <RadioField
            radioData={radioData}
            name="statementRequired"
            color={color}
            handleRadioButton={(name, value) => updateFields(name, value)}
          />
        </Styled.ColStyle>
        <Styled.ColStyle xs={6}>
          <Styled.InputContainer color={color}>
            {translation('PurchaseOrders')}
          </Styled.InputContainer>
          <RadioField
            radioData={purchaseRadioData}
            name="purchaseOrders"
            color={color}
            handleRadioButton={(name, value) => updateFields(name, value)}
          />
        </Styled.ColStyle>
        <Styled.ColStyle xs={12}>
          <TextAreaField
            value={otherSpecifications}
            label={translation('OtherSpecific')}
            name="otherSpecifications"
            type="text"
            color={color}
            handleTextInput={(name, value) => {
              updateFields(name, value);
              setOtherSpecifications(value);
            }}
            handleTextError={(name, value) => {
              updateErrorFields(name, value);
            }}
          />
        </Styled.ColStyle>
      </Styled.RowStyle>
      <Styled.HorizontalBreak />
    </>
  );
};
const mapStateToProps = (stateData) => {
  return {
    state: stateData,
  };
};

export default connect(mapStateToProps)(AccountRequirements);
