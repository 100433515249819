import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';

export const RowStyle = styled(Row)`
  margin: 0 auto !important;
`;
export const ColStyle = styled(Col)`
  padding-right: 1px !important;
  padding-left: 0 !important;
  margin-bottom: 16px;
`;
export const HorizontalBreak = styled.hr`
  border: none;
  border-top: 1px dashed #dedede;
  margin: 8px 0 24px 0;
`;

export const HeaderLayout = styled.h2`
  font-family: 'Arial';
  color: ${(props) => props.color};
  font-size: 21px;
  margin: 16px 0 16px 0;
  font-weight: bold;
`;

export const InputContainer = styled.label`
  background-color: #ededed;
  color: ${(props) => props.color};
  display: block;
  width: 100%;
  min-height: 31px;
  margin-bottom: 1px;
  padding: 8px;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
  clear: both;
  font-family: Arial;
`;
