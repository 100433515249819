import React, { useRef, useState } from 'react';
import * as Styled from './partnersPrincipalsOfficersStyles';
import { useTranslation } from 'react-i18next';
import TextField from '../../common/formSections/textField/textField';
import { connect, useDispatch, useSelector } from 'react-redux';
import creditApplication from '../../constants/creditApplication';
import { updateCreditApplication } from '../../store/action/action';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';
import 'moment/min/locales.min';
import { updateError } from '../../store/action/errorAction';
import errorData from '../../constants/errorData';
import { isNotNull, validateForm } from '../../utils/validations';
import { AppColors } from '../../constants';

const PartnersPrincipalsOfficers = () => {
  const [translation] = useTranslation();
  const getSession = useSelector((state) => state.Reducer.stateData);
  const errorStoreData = useSelector((state) => state.ErrorReducer.errorData);
  const [errorMessage, setErrorMessage] = useState('');
  const updateErrorMessage = (value) => {
    setErrorMessage(value);
  };
  const today = moment().subtract(1, 'day');
  const valid = function (current) {
    return current.isBefore(today);
  };
  const getWebData = useSelector((state) => state.websiteDataReducer.stateData);
  const color = getWebData.appColor;
  let index = 0;
  const multiTypeFieldData = [
    [
      {
        label: translation('FullName'),
        name: 'applicantsPartnersFullName1',
        value: getSession?.applicantsPartners?.applicantsPartnersFullName1,
      },
      {
        label: translation('Phone'),
        name: 'applicantsPartnersPhone1',
        value: getSession?.applicantsPartners?.applicantsPartnersPhone1,
      },
      {
        label: translation('Position'),
        name: 'applicantsPartnersPositions1',
        value: getSession?.applicantsPartners?.applicantsPartnersPositions1,
      },
      {
        label: translation('DOB'),
        name: 'applicantsPartnersDateOfBirth1',
        value: getSession?.applicantsPartners?.applicantsPartnersDateOfBirth1,
      },
      {
        label: translation('SIN'),
        name: 'applicantsPartnersSin1',
        value: getSession?.applicantsPartners?.applicantsPartnersSin1,
      },
    ],
    [
      {
        label: translation('FullName'),
        name: 'applicantsPartnersFullName2',
        value: getSession?.applicantsPartners?.applicantsPartnersFullName2,
      },
      {
        label: translation('Phone'),
        name: 'applicantsPartnersPhone2',
        value: getSession?.applicantsPartners?.applicantsPartnersPhone2,
      },
      {
        label: translation('Position'),
        name: 'applicantsPartnersPositions2',
        value: getSession?.applicantsPartners?.applicantsPartnersPositions2,
      },
      {
        label: translation('DOB'),
        name: 'applicantsPartnersDateOfBirth2',
        value: getSession?.applicantsPartners?.applicantsPartnersDateOfBirth2,
      },
      {
        label: translation('SIN'),
        name: 'applicantsPartnersSin2',
        value: getSession?.applicantsPartners?.applicantsPartnersSin2,
      },
    ],
    [
      {
        label: translation('FullName'),
        name: 'applicantsPartnersFullName3',
        value: getSession?.applicantsPartners?.applicantsPartnersFullName3,
      },
      {
        label: translation('Phone'),
        name: 'applicantsPartnersPhone3',
        value: getSession?.applicantsPartners?.applicantsPartnersPhone3,
      },
      {
        label: translation('Position'),
        name: 'applicantsPartnersPositions3',
        value: getSession?.applicantsPartners?.applicantsPartnersPositions3,
      },
      {
        label: translation('DOB'),
        name: 'applicantsPartnersDateOfBirth3',
        value: getSession?.applicantsPartners?.applicantsPartnersDateOfBirth3,
      },
      {
        label: translation('SIN'),
        name: 'applicantsPartnersSin3',
        value: getSession?.applicantsPartners?.applicantsPartnersSin3,
      },
    ],
  ];
  const [applicantsPartnersFullName1, setApplicantsPartnersFullName1] =
    useState(getSession?.applicantsPartners?.applicantsPartnersFullName1);
  const [applicantsPartnersFullName2, setApplicantsPartnersFullName2] =
    useState(getSession?.applicantsPartners?.applicantsPartnersFullName2);
  const [applicantsPartnersFullName3, setApplicantsPartnersFullName3] =
    useState(getSession?.applicantsPartners?.applicantsPartnersFullName3);
  const [applicantsPartnersPhone1, setApplicantsPartnersPhone1] = useState(
    getSession?.applicantsPartners?.applicantsPartnersPhone1
  );
  const [applicantsPartnersPhone2, setApplicantsPartnersPhone2] = useState(
    getSession?.applicantsPartners?.applicantsPartnersPhone2
  );
  const [applicantsPartnersPhone3, setApplicantsPartnersPhone3] = useState(
    getSession?.applicantsPartners?.applicantsPartnersPhone3
  );
  const [applicantsPartnersPositions1, setApplicantsPartnersPositions1] =
    useState(getSession?.applicantsPartners?.applicantsPartnersPositions1);
  const [applicantsPartnersPositions2, setApplicantsPartnersPositions2] =
    useState(getSession?.applicantsPartners?.applicantsPartnersPositions2);
  const [applicantsPartnersPositions3, setApplicantsPartnersPositions3] =
    useState(getSession?.applicantsPartners?.applicantsPartnersPositions3);
  const [applicantsPartnersDateOfBirth1, setApplicantsPartnersDateOfBirth1] =
    useState(getSession?.applicantsPartners?.applicantsPartnersDateOfBirth1);
  const [applicantsPartnersDateOfBirth2, setApplicantsPartnersDateOfBirth2] =
    useState(getSession?.applicantsPartners?.applicantsPartnersDateOfBirth2);
  const [applicantsPartnersDateOfBirth3, setApplicantsPartnersDateOfBirth3] =
    useState(getSession?.applicantsPartners?.applicantsPartnersDateOfBirth3);
  const [applicantsPartnersSin1, setApplicantsPartnersSin1] = useState(
    getSession?.applicantsPartners?.applicantsPartnersSin1
  );
  const [applicantsPartnersSin2, setApplicantsPartnersSin2] = useState(
    getSession?.applicantsPartners?.applicantsPartnersSin2
  );
  const [applicantsPartnersSin3, setApplicantsPartnersSin3] = useState(
    getSession?.applicantsPartners?.applicantsPartnersSin3
  );
  const dispatch = useDispatch();
  let creditCopyObject = Object.assign(
    {},
    getSession ? getSession : creditApplication
  );
  let errorCopyObject = Object.assign(
    {},
    errorStoreData ? errorStoreData : errorData
  );
  const updateFields = (key, value) => {
    creditCopyObject['applicantsPartners'] = {
      ...creditCopyObject['applicantsPartners'],
      [key]: value,
    };
    dispatch(updateCreditApplication(creditCopyObject));
  };
  const updateErrorFields = (key, value) => {
    errorCopyObject = {
      ...errorCopyObject,
      [key]: value,
    };
    dispatch(updateError(errorCopyObject));
  };
  const dateInputRef = useRef(null);
  const language = getWebData.language;
  return (
    <>
      <Styled.HeaderLayout color={color}>
        {translation('ApplicantsPartners')}
      </Styled.HeaderLayout>
      {multiTypeFieldData.map(
        (item) => (
          index++,
          (
            <Styled.RowStyle key={index}>
              <Styled.ColStyle xs={'auto'}>
                <Styled.InputContainer color={color}>
                  {`(${index})`}
                </Styled.InputContainer>
              </Styled.ColStyle>
              <Styled.ColStyle xs={3}>
                <TextField
                  value={item[0].value}
                  label={item[0].label}
                  name={item[0].name}
                  type="text"
                  color={color}
                  handleTextInput={(name, value) => {
                    updateFields(name, value);
                    name === 'applicantsPartnersFullName1'
                      ? setApplicantsPartnersFullName1(value)
                      : name === 'applicantsPartnersFullName2'
                      ? setApplicantsPartnersFullName2(value)
                      : setApplicantsPartnersFullName3(value);
                  }}
                  handleTextError={(name, value) => {
                    updateErrorFields(name, value);
                  }}
                />
              </Styled.ColStyle>
              <Styled.ColStyle xs>
                <TextField
                  value={item[1].value}
                  label={item[1].label}
                  name={item[1].name}
                  type="number"
                  color={color}
                  handleTextInput={(name, value) => {
                    updateFields(name, value);
                    name === 'applicantsPartnersPhone1'
                      ? setApplicantsPartnersPhone1(value)
                      : name === 'applicantsPartnersPhone2'
                      ? setApplicantsPartnersPhone2(value)
                      : setApplicantsPartnersPhone3(value);
                  }}
                  handleTextError={(name, value) => {
                    updateErrorFields(name, value);
                  }}
                />
              </Styled.ColStyle>
              <Styled.ColStyle xs={3}>
                <TextField
                  value={item[2].value}
                  label={item[2].label}
                  name={item[2].name}
                  type="text"
                  color={color}
                  handleTextInput={(name, value) => {
                    updateFields(name, value);
                    name === 'applicantsPartnersPositions1'
                      ? setApplicantsPartnersPositions1(value)
                      : name === 'applicantsPartnersPositions2'
                      ? setApplicantsPartnersPositions2(value)
                      : setApplicantsPartnersPositions3(value);
                  }}
                  handleTextError={(name, value) => {
                    updateErrorFields(name, value);
                  }}
                />
              </Styled.ColStyle>
              <Styled.ColStyle xs>
                <Styled.DateContainer
                  color={color}
                  style={
                    getWebData.isSubmitted && !isNotNull(item[3].value)
                      ? { color: AppColors.Red }
                      : getWebData.isSubmitted &&
                        (errorStoreData[item[3].name] !== '' ||
                          errorStoreData[item[3].name] === undefined) &&
                        isNotNull(item[3].value)
                      ? { color: AppColors.Red }
                      : {}
                  }
                >
                  {item[3].label}
                  <Styled.DatetimeStyle
                    locale={language}
                    errorStoreData={errorStoreData}
                    index={item[3].name}
                    ref={dateInputRef}
                    isSubmitted={getWebData.isSubmitted}
                    timeFormat={false}
                    value={item[3].value}
                    dateFormat={'MMM. DD, YYYY'}
                    closeOnSelect={true}
                    isValidDate={valid}
                    onClose={(e) => {
                      updateFields(
                        item[3].name,
                        moment(e).format('MMM. DD YYYY')
                      );
                      item[3].name === 'applicantsPartnersDateOfBirth1'
                        ? setApplicantsPartnersDateOfBirth1(
                            moment(e).format('MMM. DD, YYYY')
                          )
                        : item[3].name === 'applicantsPartnersDateOfBirth2'
                        ? setApplicantsPartnersDateOfBirth2(
                            moment(e).format('MMM. DD, YYYY')
                          )
                        : setApplicantsPartnersDateOfBirth3(
                            moment(e).format('MMM. DD, YYYY')
                          );
                      validateForm(
                        item[3].name,
                        'date',
                        moment(e).format('MMM. DD, YYYY'),
                        { dateInputRef },
                        updateErrorFields,
                        'required',
                        item[3].label,
                        updateErrorMessage,
                        translation
                      );
                    }}
                  />
                </Styled.DateContainer>
              </Styled.ColStyle>
              <Styled.ColStyle xs>
                <TextField
                  value={item[4].value}
                  label={item[4].label}
                  name={item[4].name}
                  type="text"
                  color={color}
                  handleTextInput={(name, value) => {
                    updateFields(name, value);
                    name === 'applicantsPartnersSin1'
                      ? setApplicantsPartnersSin1(value)
                      : name === 'applicantsPartnersSin2'
                      ? setApplicantsPartnersSin2(value)
                      : setApplicantsPartnersSin3(value);
                  }}
                  handleTextError={(name, value) => {
                    updateErrorFields(name, value);
                  }}
                />
              </Styled.ColStyle>
            </Styled.RowStyle>
          )
        )
      )}
    </>
  );
};
const mapStateToProps = (stateData) => {
  return {
    state: stateData,
  };
};

export default connect(mapStateToProps)(PartnersPrincipalsOfficers);
