import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { AppColors } from '../../constants';

export const MainDiv = styled.div`
  margin-top: 16px;
  margin-bottom: 14px;
`;
export const ButtonDiv = styled.div`
  float: right;
`;

export const Text = styled.div`
  font-size: 12px;
  font-style: italic;
  font-family: Arial;
  color: ${AppColors.Gray};
`;

export const ButtonStyle = styled(Button)`
  width: 100px;
  color: ${AppColors.LightGray} !important;
  font-weight: bold !important;
  text-shadow: 0px 1px 1px ${AppColors.White};
  background: linear-gradient(to bottom, #eeeeee 0%, #cccccc 100%) !important;
  height: 32px;
  font-family: Arial;
  font-size: 12px !important;
  padding: 8px !important;
  border: 1px solid lightgray !important;
  border-radius: 0 !important;
  vertical-align: top !important;
  margin-right: 5px;
  line-height: 0 !important;
  :focus {
    box-shadow: none !important;
  }
  :hover {
    color: ${AppColors.Gray} !important;
    background: linear-gradient(
      to bottom,
      #cccccc 0%,
      darkgray 100%
    ) !important;
  }
`;
export const ErrorMsgDiv = styled.div`
  color: ${AppColors.Red};
  font-style: italic;
  font-family: Arial;
  font-size: 14px;
`;
export const ErrorMsgHeader = styled.div`
  margin-bottom: 16px;
`;
export const ErrorMsg = styled.div`
  color: ${AppColors.Red};
`;
export const ErrorMsgContact = styled.div`
  margin-top: 16px;
  font-weight: bold;
  font-style: normal;
`;
export const LinkStyle = styled.a`
  color: ${AppColors.Red};
  :hover {
    color: ${AppColors.Red} !important;
  }
`;
