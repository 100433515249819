import React, { useState } from 'react';
import * as Styled from './contactDetailsStyles';
import { useTranslation } from 'react-i18next';
import MultiTypeFields from '../../common/formSections/multiTypeFields/multiTypeFields';
import { connect, useDispatch, useSelector } from 'react-redux';
import { updateCreditApplication } from '../../store/action/action';
import creditApplication from '../../constants/creditApplication';
import errorData from '../../constants/errorData';
import { updateError } from '../../store/action/errorAction';

const ContactDetails = () => {
  const [translation] = useTranslation();
  const dispatch = useDispatch();
  const getStoreData = useSelector((state) => state.Reducer.stateData);
  const errorStoreData = useSelector((state) => state.ErrorReducer.errorData);
  const getWebData = useSelector((state) => state.websiteDataReducer.stateData);
  const color = getWebData.appColor;

  const multiTypeFieldData = [
    {
      label: translation('Phone'),
      type: 'number',
      name: 'phone',
      required: 'required',
      value: getStoreData?.contactInfo?.phone,
    },
    {
      label: translation('CellPhone'),
      type: 'number',
      name: 'cellPhone',
      value: getStoreData?.contactInfo?.cellPhone,
    },
    {
      label: translation('Fax'),
      type: 'text',
      name: 'fax',
      value: getStoreData?.contactInfo?.fax,
    },
  ];
  const [phone, setPhone] = useState(getStoreData?.contactInfo?.phone);
  const [cellPhone, setCellPhone] = useState(
    getStoreData?.contactInfo?.cellPhone
  );
  const [fax, setFax] = useState(getStoreData?.contactInfo?.fax);
  let creditCopyObject = Object.assign(
    {},
    getStoreData ? getStoreData : creditApplication
  );
  let errorCopyObject = Object.assign(
    {},
    errorStoreData ? errorStoreData : errorData
  );
  const updateFields = (key, value) => {
    creditCopyObject['contactInfo'] = {
      ...creditCopyObject['contactInfo'],
      [key]: value,
    };
    dispatch(updateCreditApplication(creditCopyObject));
  };
  const updateErrorFields = (key, value) => {
    errorCopyObject = {
      ...errorCopyObject,
      [key]: value,
    };
    dispatch(updateError(errorCopyObject));
  };

  return (
    <>
      <MultiTypeFields
        multiTypeFieldData={multiTypeFieldData}
        color={color}
        handleMultiType={(name, value) => {
          updateFields(name, value);
        }}
        handleError={(name, value) => {
          updateErrorFields(name, value);
          name === 'phone'
            ? setPhone(value)
            : name === 'fax'
            ? setFax(value)
            : setCellPhone(value);
        }}
      />
      <Styled.HorizontalBreak />
    </>
  );
};
const mapStateToProps = (stateData) => {
  return {
    state: stateData,
  };
};

export default connect(mapStateToProps)(ContactDetails);
