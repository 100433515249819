import React, { useRef, useState } from 'react';
import * as Styled from './applicantTypeStyles';
import TextField from '../../common/formSections/textField/textField';
import { useTranslation } from 'react-i18next';
import RadioField from '../../common/formSections/radioField/radioField';
import { connect, useDispatch, useSelector } from 'react-redux';
import { updateCreditApplication } from '../../store/action/action';
import creditApplication from '../../constants/creditApplication';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';
import 'moment/min/locales.min';
import { updateError } from '../../store/action/errorAction';
import errorData from '../../constants/errorData';
import { isNotNull, validateForm } from '../../utils/validations';
import { AppColors } from '../../constants';

const ApplicantType = () => {
  const [translation] = useTranslation();
  const getWebData = useSelector((state) => state.websiteDataReducer.stateData);
  const color = getWebData.appColor;
  const dispatch = useDispatch();
  const getStoreData = useSelector((state) => state.Reducer.stateData);
  const errorStoreData = useSelector((state) => state.ErrorReducer.errorData);
  const [errorMessage, setErrorMessage] = useState('');

  const updateErrorMessage = (value) => {
    setErrorMessage(value);
  };
  const applicantTypes = [
    {
      label: translation('Corporation'),
      required: 'required',
      value: getStoreData?.generalInformation?.applicantIsA.includes(
        translation('Corporation')
      )
        ? true
        : false,
    },
    {
      label: translation('Partnership'),
      required: 'required',
      value: getStoreData?.generalInformation?.applicantIsA.includes(
        translation('Partnership')
      )
        ? true
        : false,
    },
    {
      label: translation('SoleProprietorship'),
      required: 'required',
      value: getStoreData?.generalInformation?.applicantIsA.includes(
        translation('SoleProprietorship')
      )
        ? true
        : false,
    },
    {
      label: translation('Other'),
      required: 'required',
      value: getStoreData?.generalInformation?.applicantIsA.includes(
        translation('Other')
      )
        ? true
        : false,
    },
  ];

  const [applicantIsA, setApplicantIsA] = useState(
    getStoreData?.generalInformation?.applicantIsA
  );
  const [generalInformationOther, setGeneralInformationOther] = useState(
    getStoreData?.generalInformation?.generalInformationOther
  );
  const [date, setDate] = useState(
    getStoreData?.generalInformation?.dateApplicantWasEstablished
  );
  const [applicantIsaDivisionOf, setApplicantIsaDivisionOf] = useState(
    getStoreData?.generalInformation?.applicantIsaDivisionOf
  );
  const [natureOfApplicantsBusiness, setNatureOfApplicantsBusiness] = useState(
    getStoreData?.generalInformation?.natureOfApplicantsBusiness
  );
  const [operatingUnderCurrentOwnership, setOperatingUnderCurrentOwnership] =
    useState(getStoreData?.generalInformation?.operatingUnderCurrentOwnership);

  let creditCopyObject = Object.assign(
    {},
    getStoreData ? getStoreData : creditApplication
  );
  let errorCopyObject = Object.assign(
    {},
    errorStoreData ? errorStoreData : errorData
  );
  const updateFields = (key, value) => {
    creditCopyObject['generalInformation'] = {
      ...creditCopyObject['generalInformation'],
      [key]: value,
    };
    dispatch(updateCreditApplication(creditCopyObject));
  };
  const updateErrorFields = (key, value) => {
    errorCopyObject = {
      ...errorCopyObject,
      [key]: value,
    };
    dispatch(updateError(errorCopyObject));
  };
  const language = getWebData.language;
  const dateInputRef = useRef(null);
  const dateColor =
    getWebData.isSubmitted && !isNotNull(date)
      ? AppColors.Red
      : getWebData.isSubmitted &&
        (errorStoreData['dateApplicantWasEstablished'] !== '' ||
          errorStoreData['dateApplicantWasEstablished'] === undefined) &&
        isNotNull(date)
      ? AppColors.Red
      : color;
  return (
    <>
      <Styled.SubHeader color={color}>
        {translation('ApplicantIsA')}
      </Styled.SubHeader>
      <RadioField
        value={applicantIsA}
        radioData={applicantTypes}
        name="applicantIsA"
        color={color}
        handleRadioButton={(name, value) => updateFields(name, value)}
        handleError={(name, value) => {
          updateErrorFields(name, value);
        }}
      />
      <TextField
        value={generalInformationOther}
        label={translation('OtherFrench')}
        name="generalInformationOther"
        type="text"
        color={color}
        handleTextInput={(name, value) => {
          updateFields(name, value);
          setGeneralInformationOther(value);
        }}
        handleTextError={(name, value) => {
          updateErrorFields(name, value);
        }}
      />
      <Styled.HorizontalBreak />
      <Styled.DateContainer color={dateColor}>
        * {translation('DateApplicantEstablished')}
        <Styled.DatetimeStyle
          errorStoreData={errorStoreData}
          ref={dateInputRef}
          isSubmitted={getWebData.isSubmitted}
          locale={language}
          value={date}
          timeFormat={false}
          dateFormat={'MMM. DD, YYYY'}
          closeOnSelect={true}
          onClose={(e) => {
            setDate(moment(e).format('MMM. DD, YYYY'));
            updateFields(
              'dateApplicantWasEstablished',
              moment(e).format('MMM. DD, YYYY')
            );
            validateForm(
              'dateApplicantWasEstablished',
              'date',
              moment(e).format('MMM. DD, YYYY'),
              { dateInputRef },
              updateErrorFields,
              'required',
              'Date Applicant was established/registered/incorporated',
              updateErrorMessage,
              translation
            );
          }}
        />
      </Styled.DateContainer>
      <Styled.ErrorContainer>{errorMessage}</Styled.ErrorContainer>

      <TextField
        value={applicantIsaDivisionOf}
        label={translation('ApplicantIsDivision')}
        name="applicantIsaDivisionOf"
        type="text"
        color={color}
        handleTextInput={(name, value) => {
          updateFields(name, value);
          setApplicantIsaDivisionOf(value);
        }}
        handleTextError={(name, value) => {
          updateErrorFields(name, value);
        }}
      />
      <TextField
        value={natureOfApplicantsBusiness}
        label={translation('NatureOfBusiness')}
        name="natureOfApplicantsBusiness"
        type="text"
        color={color}
        required="required"
        handleTextInput={(name, value) => {
          updateFields(name, value);
          setNatureOfApplicantsBusiness(value);
        }}
        handleTextError={(name, value) => {
          updateErrorFields(name, value);
        }}
      />
      <TextField
        value={operatingUnderCurrentOwnership}
        label={translation('YearsApplicantOperating')}
        name="operatingUnderCurrentOwnership"
        type="text"
        color={color}
        required="required"
        handleTextInput={(name, value) => {
          updateFields(name, value);
          setOperatingUnderCurrentOwnership(value);
        }}
        handleTextError={(name, value) => {
          updateErrorFields(name, value);
        }}
      />
    </>
  );
};
const mapStateToProps = (stateData) => {
  return {
    state: stateData,
  };
};

export default connect(mapStateToProps)(ApplicantType);
