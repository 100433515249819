import React, { useState } from 'react';
import * as Styled from './termsAndConditionsStyles';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import creditApplication from '../../constants/creditApplication';
import { updateCreditApplication } from '../../store/action/action';
import { useTranslation } from 'react-i18next';
import errorData from '../../constants/errorData';
import { updateError } from '../../store/action/errorAction';
import { AppColors } from '../../constants';

const TermsAndCondition = ({ termsAndConditions }) => {
  const [translation] = useTranslation();
  const dispatch = useDispatch();
  const getStoreData = useSelector((state) => state.Reducer.stateData);
  const errorStoreData = useSelector((state) => state.ErrorReducer.errorData);
  const getWebData = useSelector((state) => state.websiteDataReducer.stateData);
  const [checkBox, setCheckBox] = useState(getStoreData?.iAgree);
  const color = getWebData.appColor;
  let creditCopyObject = Object.assign(
    {},
    getStoreData ? getStoreData : creditApplication
  );
  let errorCopyObject = Object.assign(
    {},
    errorStoreData ? errorStoreData : errorData
  );
  const updateFields = (key, value) => {
    creditCopyObject[key] = value;
    dispatch(updateCreditApplication(creditCopyObject));
  };
  const updateErrorFields = (key, value) => {
    errorCopyObject = {
      ...errorCopyObject,
      [key]: value,
    };
    dispatch(updateError(errorCopyObject));
  };
  const headerTextData = termsAndConditions.node.sections.filter(
    (section) => section.type === 'headerWithText'
  )[0];

  const contentList = termsAndConditions.node.sections.filter(
    (section) => section.type === 'termsAndConditionsContentList'
  )[0];

  const noteSection = contentList?.list[0];
  const termsSection = contentList?.list[1];
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <Styled.ListText color="#505050">{children}</Styled.ListText>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaText color="#505050">{children}</Styled.ParaText>
      ),
      [BLOCKS.HEADING_1]: (node, children) => (
        <Styled.Header color="#505050">{children}</Styled.Header>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <Styled.Header2 color="#505050">{children}</Styled.Header2>
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
  };
  const optionsNoteStyle = {
    renderNode: {
      [BLOCKS.HEADING_3]: (node, children) => (
        <Styled.Header3 color={color}>{children}</Styled.Header3>
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
  };
  const labelColor =
    getWebData.isSubmitted && !checkBox ? AppColors.Red : color;
  return (
    <>
      <Styled.HeaderLayout color={color}>
        {headerTextData?.headerText}
      </Styled.HeaderLayout>
      <Styled.SubText>{headerTextData?.text?.text}</Styled.SubText>
      <Styled.TermsContainer>
        {noteSection &&
          documentToReactComponents(
            JSON.parse(noteSection.contentDetails.raw),
            optionsNoteStyle
          )}
        {termsSection &&
          documentToReactComponents(
            JSON.parse(termsSection.contentDetails.raw),
            optionsMainStyle
          )}
      </Styled.TermsContainer>
      <Styled.RowStyle>
        <Styled.ColStyle>
          <Styled.Label color={labelColor}>
            {translation('IAccept')}
            <Styled.InputField
              name="iAgree"
              value={checkBox}
              checked={checkBox}
              type="checkbox"
              onChange={() => {
                setCheckBox(!checkBox);
                updateFields('iAgree', !checkBox);
                checkBox
                  ? updateErrorFields(
                      'iAgree',
                      translation('IAccept') + ' required'
                    )
                  : updateErrorFields('iAgree', '');
              }}
            />
          </Styled.Label>
        </Styled.ColStyle>
      </Styled.RowStyle>
      <Styled.NoteContainer>
        <Styled.SubText italic={'italic'}>
          {translation('BySelectingIACCEPT')}
        </Styled.SubText>
      </Styled.NoteContainer>
      <Styled.HorizontalBreak />
    </>
  );
};
const mapStateToProps = (stateData) => {
  return {
    state: stateData,
  };
};

export default connect(mapStateToProps)(TermsAndCondition);
