import React, { useState } from 'react';
import * as Styled from './generalInformationStyles';
import TextField from '../../common/formSections/textField/textField';
import { useTranslation } from 'react-i18next';
import MultiTypeFields from '../../common/formSections/multiTypeFields/multiTypeFields';
import SelectField from '../../common/formSections/selectField/selectField';
import { connect, useDispatch, useSelector } from 'react-redux';
import { updateCreditApplication } from '../../store/action/action';
import creditApplication from '../../constants/creditApplication';
import { updateError } from '../../store/action/errorAction';
import errorData from '../../constants/errorData';
import { selectClosestBranchDropDown } from '../../utils/closestBranchDropdown';

const GeneralInformation = ({ closestBranchList }) => {
  const [translation] = useTranslation();
  const dispatch = useDispatch();
  const getStoreData = useSelector((state) => state.Reducer.stateData);
  const errorStoreData = useSelector((state) => state.ErrorReducer.errorData);
  const getWebData = useSelector((state) => state.websiteDataReducer.stateData);
  const color = getWebData.appColor;
  //Add Select Text to the List
  const selectArray = [translation('SelectOne')];
  const finalClosestBranchList = [...selectArray, ...closestBranchList];
  const multiTypeFieldData = [
    {
      label: translation('GST'),
      type: 'text',
      name: 'gst',
      required: 'required',
      value: getStoreData?.generalInformation?.gst,
    },
    {
      label: translation('HST'),
      type: 'text',
      name: 'hst',
      value: getStoreData?.generalInformation?.hst,
    },
    {
      label: translation('PST'),
      type: 'text',
      name: 'pst',
      value: getStoreData?.generalInformation?.pst,
    },
  ];
  const [companyName, setCompanyName] = useState(
    getStoreData?.generalInformation?.registeredCompanyName
  );
  const [operatingName, setOperatingName] = useState(
    getStoreData?.generalInformation?.operatingName
  );
  const [pst, setPST] = useState(getStoreData?.generalInformation?.pst);
  const [gst, setGST] = useState(getStoreData?.generalInformation?.gst);
  const [hst, setHST] = useState(getStoreData?.generalInformation?.hst);
  const [closestBranchDropDown, setClosestBranchDropDown] = useState(
    getStoreData?.generalInformation?.closestBranch
  );
  const creditCopyObject = Object.assign(
    {},
    getStoreData ? getStoreData : creditApplication
  );
  let errorCopyObject = Object.assign(
    {},
    errorStoreData ? errorStoreData : errorData
  );
  const updateFields = (key, value) => {
    creditCopyObject['generalInformation'] = {
      ...creditCopyObject['generalInformation'],
      [key]: value,
    };
    dispatch(updateCreditApplication(creditCopyObject));
  };
  const updateErrorFields = (key, value) => {
    errorCopyObject = {
      ...errorCopyObject,
      [key]: value,
    };
    dispatch(updateError(errorCopyObject));
  };
  // const closestBranchList = selectClosestBranchDropDown();
  return (
    <>
      <Styled.HeaderLayout color={color}>
        {translation('GeneralInformation')}
      </Styled.HeaderLayout>

      <TextField
        value={companyName}
        label={translation('LegalCompanyName')}
        name="registeredCompanyName"
        type="text"
        color={color}
        required="required"
        handleTextInput={(name, value) => {
          updateFields(name, value);
          setCompanyName(value);
        }}
        handleTextError={(name, value) => {
          updateErrorFields(name, value);
        }}
      />
      <TextField
        value={operatingName}
        label={translation('OperatingName')}
        name="operatingName"
        type="text"
        color={color}
        handleTextInput={(name, value) => {
          updateFields(name, value);
          setOperatingName(name);
        }}
        handleTextError={(name, value) => {
          updateErrorFields(name, value);
        }}
      />
      <MultiTypeFields
        multiTypeFieldData={multiTypeFieldData}
        color={color}
        handleMultiType={(name, value) => {
          updateFields(name, value);
          name === 'gst'
            ? setGST(value)
            : name === 'pst'
            ? setPST(value)
            : setHST(value);
        }}
        handleError={(name, value) => {
          updateErrorFields(name, value);
        }}
      />
      <SelectField
        value={closestBranchDropDown}
        label={translation('ClosestBranch')}
        name="closestBranch"
        color={color}
        optionList={finalClosestBranchList}
        required="required"
        handleSelectField={(name, value) => {
          updateFields(name, value);
          setClosestBranchDropDown(value);
        }}
        handleError={(name, value) => {
          updateErrorFields(name, value);
        }}
      />
    </>
  );
};
const mapStateToProps = (stateData) => {
  return {
    state: stateData,
  };
};

export default connect(mapStateToProps)(GeneralInformation);
