import * as React from 'react';
import { Helmet } from 'react-helmet';
import { I18nextProvider, useTranslation } from 'react-i18next';
import i18next from '../../i18next';
import * as Styled from '../../styles';
import Logo from '../logo/logo';
import { graphql, useStaticQuery } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import InvalidDetails from '../invalidDetails/invalidDetails';

const ThankYou = () => {
  const data = useStaticQuery(graphql`
    query BrandsThankYou {
      allContentfulBrands {
        edges {
          node {
            node_locale
            id
            brand
            creditColour
            creditLogo {
              file {
                url
                details {
                  image {
                    width
                    height
                  }
                }
              }
            }
            creditText {
              creditText
            }
          }
        }
      }
      allContentfulGeneralPageThankYou(
        filter: { name: { eq: "Credit Parkland" } }
      ) {
        edges {
          node {
            title
            type {
              type
            }
            slug
            node_locale
            sections {
              type
              title
              header
              contentDetails {
                raw
              }
            }
          }
        }
      }
    }
  `);
  const details = data?.allContentfulBrands?.edges?.map((node) => {
    return node?.node.node_locale;
  });
  const isBrowser = typeof window !== 'undefined';
  const languageList = [...new Set(details)];
  const searchParam = isBrowser ? window.location.search : null;
  const params = new URLSearchParams(searchParam);
  const language = languageList.includes(params?.get('lang'))
    ? params.get('lang')
    : 'en-US';
  const [translate] = useTranslation();
  const companyParam = params?.get('company')
    ? params.get('company').toLowerCase()
    : '';
  const typeParam = params?.get('type');

  const companyDetailsList = data.allContentfulBrands.edges.filter((node) => {
    return node?.node?.node_locale === language;
  });

  const currentCompanyDetails = companyDetailsList.filter((node) => {
    return node?.node?.brand.toLowerCase() === companyParam;
  });
  const appColor = currentCompanyDetails[0]?.node?.creditColour;
  const creditLogo = currentCompanyDetails[0]?.node?.creditLogo.file.url;
  const creditText = currentCompanyDetails[0]?.node?.creditText.creditText;

  const thankYouPageDetails =
    data.allContentfulGeneralPageThankYou.edges.filter((node) => {
      return node?.node?.node_locale === language;
    });
  const currentThankyouPageDetails = thankYouPageDetails.filter((node) => {
    return node?.node?.type.type.toLowerCase() === companyParam;
  });
  const thankYouPageData = currentThankyouPageDetails[0]?.node?.sections.filter(
    (section) => section.type === 'richTextWithParagraph'
  )[0];

  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ThankYouText>{children}</Styled.ThankYouText>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <Styled.ThankYouAnchor color={appColor} href={node.data.uri}>
          {children}
        </Styled.ThankYouAnchor>
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
  };
  const isValidUrl = () => {
    const companyList = companyDetailsList.map((node) => node?.node?.brand);
    const isValidType = typeParam === 'commercial';
    const isValidCompany = companyList
      .map((element) => element.toLowerCase())
      .includes(companyParam);
    return isValidCompany && isValidType;
  };
  return isValidUrl() ? (
    <>
      <I18nextProvider i18n={i18next}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Parkland Credit - Thank You</title>
        </Helmet>
        <Styled.PageContainer>
          <Logo creditLogo={creditLogo} creditText={creditText} />
          <Styled.ThankYouHeader color={appColor}>
            {thankYouPageData.title}
          </Styled.ThankYouHeader>
          {thankYouPageData &&
            documentToReactComponents(
              JSON.parse(thankYouPageData.contentDetails.raw),
              optionsMainStyle
            )}
        </Styled.PageContainer>
      </I18nextProvider>
    </>
  ) : (
    <InvalidDetails />
  );
};

export default ThankYou;
