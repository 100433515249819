import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

export const RowStyle = styled(Row)`
  margin: 0 auto !important;
`;
export const ColStyle = styled(Col)`
  padding-right: 1px !important;
  padding-left: 0 !important;
`;
