import React, { useRef, useState } from 'react';
import * as Styled from './applicantsYearEndStyles';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';
import 'moment/min/locales.min';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import creditApplication from '../../constants/creditApplication';
import { updateCreditApplication } from '../../store/action/action';
import { isNotNull, validateForm } from '../../utils/validations';
import errorData from '../../constants/errorData';
import { updateError } from '../../store/action/errorAction';
import { AppColors } from '../../constants';
import RadioField from '../../common/formSections/radioField/radioField';

const ApplicantsYearEnd = () => {
  const [translation] = useTranslation();
  const dispatch = useDispatch();
  const getWebData = useSelector((state) => state.websiteDataReducer.stateData);
  const color = getWebData.appColor;
  const getStoreData = useSelector((state) => state.Reducer.stateData);
  const errorStoreData = useSelector((state) => state.ErrorReducer.errorData);
  const [errorMessage, setErrorMessage] = useState('');
  const updateErrorMessage = (value) => {
    setErrorMessage(value);
  };
  const financialStatement = [
    {
      label: translation('ApplicantYearEndYes'),
      value: getStoreData?.yearEnd?.financialStatements.includes(
        translation('ApplicantYearEndYes')
      )
        ? true
        : false,
    },
    {
      label: translation('ApplicantYearEndNo'),
      value: getStoreData?.yearEnd?.financialStatements.includes(
        translation('ApplicantYearEndNo')
      )
        ? true
        : false,
    },
  ];
  let creditCopyObject = Object.assign(
    {},
    getStoreData ? getStoreData : creditApplication
  );
  let errorCopyObject = Object.assign(
    {},
    errorStoreData ? errorStoreData : errorData
  );
  const [date, setDate] = useState(getStoreData?.yearEnd?.financialYearEndDate);
  const updateFields = (key, value) => {
    creditCopyObject['yearEnd'] = {
      ...creditCopyObject['yearEnd'],
      [key]: value,
    };
    dispatch(updateCreditApplication(creditCopyObject));
  };
  const updateErrorFields = (key, value) => {
    errorCopyObject = {
      ...errorCopyObject,
      [key]: value,
    };
    dispatch(updateError(errorCopyObject));
  };
  const language = getWebData.language;
  const dateInputRef = useRef(null);
  const dateColor =
    getWebData.isSubmitted && !isNotNull(date)
      ? AppColors.Red
      : getWebData.isSubmitted &&
        (errorStoreData['financialYearEndDate'] !== '' ||
          errorStoreData['financialYearEndDate'] === undefined) &&
        isNotNull(date)
      ? AppColors.Red
      : color;
  return (
    <>
      <Styled.SubHeader color={color}>
        {translation('ApplicantsYearEnd')}
      </Styled.SubHeader>
      <Styled.InputContainer color={color}>
        {translation('WillProvideCopy')}
      </Styled.InputContainer>
      <RadioField
        radioData={financialStatement}
        name="financialStatements"
        color={color}
        width="6"
        handleRadioButton={(name, value) => updateFields(name, value)}
      />
      <Styled.DateContainer color={dateColor}>
        {translation('FinancialYearEnd')}
        <Styled.DatetimeStyle
          errorStoreData={errorStoreData}
          ref={dateInputRef}
          isSubmitted={getWebData.isSubmitted}
          timeFormat={false}
          value={date}
          dateFormat={'MMM. DD, YYYY'}
          closeOnSelect={true}
          locale={language}
          onClose={(e) => {
            updateFields(
              'financialYearEndDate',
              moment(e).format('MMM. DD, YYYY')
            );
            setDate(moment(e).format('MMM. DD, YYYY'));
            validateForm(
              'financialYearEndDate',
              'date',
              moment(e).format('MMM. DD, YYYY'),
              { dateInputRef },
              updateErrorFields,
              'required',
              'Financial Year End Date',
              updateErrorMessage,
              translation
            );
          }}
        />
      </Styled.DateContainer>
      <Styled.ErrorContainer>{errorMessage}</Styled.ErrorContainer>
    </>
  );
};
const mapStateToProps = (stateData) => {
  return {
    state: stateData,
  };
};

export default connect(mapStateToProps)(ApplicantsYearEnd);
