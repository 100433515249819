import React, { useState } from 'react';
import * as Styled from './schedulingStyles';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import RadioField from '../../common/formSections/radioField/radioField';
import { updateCreditApplication } from '../../store/action/action';
import creditApplication from '../../constants/creditApplication';
import TextField from '../../common/formSections/textField/textField';
import { updateError } from '../../store/action/errorAction';
import errorData from '../../constants/errorData';

const Scheduling = () => {
  const [translation] = useTranslation();
  const dispatch = useDispatch();
  const getStoreData = useSelector((state) => state.Reducer.stateData);
  const errorStoreData = useSelector((state) => state.ErrorReducer.errorData);
  const getWebData = useSelector((state) => state.websiteDataReducer.stateData);
  const color = getWebData.appColor;
  const schedulingType = [
    {
      label: translation('OneTime'),
      value: getStoreData?.scheduling?.scheduling.includes(
        translation('OneTime')
      )
        ? true
        : false,
    },
    {
      label: translation('Weekly'),
      value: getStoreData?.scheduling?.scheduling.includes(
        translation('Weekly')
      )
        ? true
        : false,
    },
    {
      label: translation('OtherFrench'),
      value: getStoreData?.scheduling?.scheduling.includes(translation('Other'))
        ? true
        : false,
    },
  ];
  const [productsAccountInformationOther, setProductsAccountInformationOther] =
    useState(getStoreData?.scheduling?.productsAccountInformationOther);
  const [noOfDays, setNoOfDays] = useState(getStoreData?.scheduling?.noOfDays);
  const [purchaseExpected, setPurchaseExpected] = useState(
    getStoreData?.scheduling?.purchaseExpected
  );
  const [litersExpected, setLitersExpected] = useState(
    getStoreData?.scheduling?.litersExpected
  );
  let creditCopyObject = Object.assign(
    {},
    getStoreData ? getStoreData : creditApplication
  );
  let errorCopyObject = Object.assign(
    {},
    errorStoreData ? errorStoreData : errorData
  );
  const updateFields = (key, value) => {
    creditCopyObject['scheduling'] = {
      ...creditCopyObject['scheduling'],
      [key]: value,
    };
    dispatch(updateCreditApplication(creditCopyObject));
  };
  const updateErrorFields = (key, value) => {
    errorCopyObject = {
      ...errorCopyObject,
      [key]: value,
    };
    dispatch(updateError(errorCopyObject));
  };
  return (
    <>
      <Styled.SubHeader color={color}>
        {translation('Scheduling')}
      </Styled.SubHeader>
      <RadioField
        radioData={schedulingType}
        name="scheduling"
        color={color}
        width="4"
        handleRadioButton={(name, value) => updateFields(name, value)}
      />

      <Styled.RowStyle>
        <Styled.ColStyle>
          <TextField
            value={productsAccountInformationOther}
            label={translation('OtherFrench')}
            name="productsAccountInformationOther"
            color={color}
            handleTextInput={(name, value) => {
              updateFields(name, value);
              setProductsAccountInformationOther(value);
            }}
            handleTextError={(name, value) => {
              updateErrorFields(name, value);
            }}
          />
        </Styled.ColStyle>
        <Styled.ColStyle>
          <TextField
            type={'number'}
            value={noOfDays}
            label={translation('Days')}
            name="noOfDays"
            color={color}
            handleTextInput={(name, value) => {
              updateFields(name, value);
              setNoOfDays(value);
            }}
            handleTextError={(name, value) => {
              updateErrorFields(name, value);
            }}
          />
        </Styled.ColStyle>
      </Styled.RowStyle>
      <Styled.HorizontalBreak />
      <Styled.RowStyle>
        <Styled.ColStyle>
          <TextField
            type={'number'}
            value={purchaseExpected}
            label={translation('EstimatedAnnualPurchases')}
            name="purchaseExpected"
            color={color}
            handleTextInput={(name, value) => {
              updateFields(name, value);
              setPurchaseExpected(value);
            }}
            handleTextError={(name, value) => {
              updateErrorFields(name, value);
            }}
          />
        </Styled.ColStyle>
        <Styled.ColStyle>
          <TextField
            value={litersExpected}
            label={translation('EstimatedAnnualLiters')}
            name="litersExpected"
            color={color}
            handleTextInput={(name, value) => {
              updateFields(name, value);
              setLitersExpected(value);
            }}
            handleTextError={(name, value) => {
              updateErrorFields(name, value);
            }}
          />
        </Styled.ColStyle>
      </Styled.RowStyle>
    </>
  );
};
const mapStateToProps = (stateData) => {
  return {
    state: stateData,
  };
};

export default connect(mapStateToProps)(Scheduling);
