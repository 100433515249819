import styled from 'styled-components';
import { AppColors } from '../../../constants';

export const InputContainer = styled.label`
  background-color: ${AppColors.Gray93};
  color: ${(props) => props.color};
  display: block;
  width: 100%;
  min-height: 31px;
  margin-bottom: 1px;
  padding: 8px;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
  clear: both;
  font-family: Arial;
`;
export const InputField = styled.textarea`
  width: 100%;
  min-height: 100px;
  resize: vertical;
  font-family: Arial;
  font-size: 12px;
  color: ${AppColors.Gray};
  background-color: ${(props) =>
    props.$background ? AppColors.LightPink : AppColors.White};
  padding: 8px;
  margin-bottom: 2px;
  border: ${(props) =>
    props.$borderColor ? '1px solid red' : '1px solid lightgray'};
`;
export const ErrorContainer = styled.div`
  color: ${AppColors.Red};
`;
