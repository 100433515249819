import React, { useRef, useState } from 'react';
import { isNotNull } from '../../../utils/validations';
import * as Styled from './selectFieldStyle';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppColors } from '../../../constants';

const SelectField = ({
  label,
  name,
  color,
  optionList,
  required,
  handleSelectField,
  value,
  handleError,
}) => {
  const [translation] = useTranslation();
  const [field, setField] = useState(value || '');
  const selectInput = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');
  const getWebData = useSelector((state) => state.websiteDataReducer.stateData);
  const labelColor =
    required &&
    getWebData.isSubmitted &&
    (!isNotNull(field) || field === translation('SelectOne'))
      ? AppColors.Red
      : color;
  const selectColor =
    required &&
    getWebData.isSubmitted &&
    (!isNotNull(field) || field === translation('SelectOne'));
  return (
    <>
      <Styled.Label color={labelColor}>
        {required ? '* ' + label : label}
        <Styled.SelectField
          $borderColor={selectColor}
          $background={selectColor}
          ref={selectInput}
          value={field}
          type="select"
          onChange={(e) => setField(e.target.value)}
          onBlur={(e) => {
            if (required && e.target.value === translation('SelectOne')) {
              handleError?.(name, label + ' required');
              selectInput.current.style.border = '1px solid red';
              selectInput.current.style.backgroundColor = '#FCDFE0';
              setErrorMessage(translation('Required'));
            } else {
              handleError?.(name, '');
              selectInput.current.style.border = '1px solid lightgray';
              selectInput.current.style.backgroundColor = '#FFF';
              setErrorMessage('');
            }
            handleSelectField(name, e.target.value);
            setField(e.target.value);
          }}
        >
          {optionList &&
            optionList.map((item, index) => (
              <option key={index}>{item}</option>
            ))}
        </Styled.SelectField>
      </Styled.Label>
      <Styled.ErrorContainer>{errorMessage}</Styled.ErrorContainer>
    </>
  );
};
export default SelectField;
