import React from 'react';
import * as Styled from './headerStyles';
import moment from 'moment';
import 'moment/min/locales.min';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const Header = () => {
  const [translation] = useTranslation();
  const getWebData = useSelector((state) => state.websiteDataReducer.stateData);
  const color = getWebData.appColor;
  const language = getWebData?.language;
  return (
    <>
      <Styled.HeaderLayout color={color}>
        {translation('CommercialCreditApplication')}
      </Styled.HeaderLayout>
      <Styled.ParaText>* {translation('RequiredField')}</Styled.ParaText>
      <Styled.DateContainer color={color}>
        * {translation('Date')}:
        <Styled.InputField
          $color="#505050"
          type="text"
          value={moment().locale(language).format('MMM DD, YYYY')}
          disabled
        />
      </Styled.DateContainer>
    </>
  );
};
export default Header;
