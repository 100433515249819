import apigClientFactory from 'aws-api-gateway-client';
import { apiGatewayClient } from './apiGateway';

export const postFormData = async (requestObject) => {
  const apigClient = apigClientFactory.newClient(apiGatewayClient);
  const additionalParams = {
    headers: {
      'Strict-Transport-Security':
        'max-age=63072000; includeSubDomains; preload',
      'X-Frame-Options': 'SAMEORIGIN',
      'Content-Security-Policy': "default-src 'self'",
    },
  };
  const response = apigClient.invokeApi(
    {},
    '',
    'POST',
    additionalParams,
    requestObject
  );
  return response;
};
