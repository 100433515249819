import { ACTION_NAMES } from '../action/websiteDataAction';

export const initialState = {};
export const websiteDataReducer = (
  state = { stateData: initialState },
  action
) => {
  switch (action.type) {
    case ACTION_NAMES.UPDATE_STATE_WEBSITE_DATA:
      return {
        stateData: action.payload,
      };
    default:
      return state;
  }
};
