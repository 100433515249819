import React, { useState } from 'react';
import * as Styled from './requiredCardlockProductsStyles';
import { useTranslation } from 'react-i18next';
import CheckBoxField from '../../common/formSections/checkBoxField/checkBoxField';
import TextField from '../../common/formSections/textField/textField';
import { connect, useDispatch, useSelector } from 'react-redux';
import creditApplication from '../../constants/creditApplication';
import { updateCreditApplication } from '../../store/action/action';
import errorData from '../../constants/errorData';
import { updateError } from '../../store/action/errorAction';

const RequiredCardlockProducts = () => {
  const [translation] = useTranslation();

  const dispatch = useDispatch();
  const getStoreData = useSelector((state) => state.Reducer.stateData);
  const errorStoreData = useSelector((state) => state.ErrorReducer.errorData);
  const getWebData = useSelector((state) => state.websiteDataReducer.stateData);
  const color = getWebData.appColor;
  const checkBoxData = [
    {
      label: translation('Diesel'),
      name: 'diesel',
      value: getStoreData?.cardlockProducts?.diesel,
    },
    {
      label: translation('MarkedDiesel'),
      name: 'markedDiesel',
      value: getStoreData?.cardlockProducts?.markedDiesel,
    },
    {
      label: translation('Gas'),
      name: 'gas',
      value: getStoreData?.cardlockProducts?.gas,
    },
    {
      label: translation('MarkedGas'),
      name: 'markedGas',
      value: getStoreData?.cardlockProducts?.markedGas,
    },
    {
      label: translation('Propane'),
      name: 'propane',
      value: getStoreData?.cardlockProducts?.propane,
    },
  ];
  const [numberOfRequiredCards, setNumberOfRequiredCards] = useState(
    getStoreData.cardlockProducts.numberOfRequiredCards
  );
  let creditCopyObject = Object.assign(
    {},
    getStoreData ? getStoreData : creditApplication
  );
  let errorCopyObject = Object.assign(
    {},
    errorStoreData ? errorStoreData : errorData
  );
  const updateFields = (key, value) => {
    creditCopyObject['cardlockProducts'] = {
      ...creditCopyObject['cardlockProducts'],
      [key]: value,
    };
    dispatch(updateCreditApplication(creditCopyObject));
  };
  const updateTextFields = (key, value) => {
    creditCopyObject = {
      ...creditCopyObject,
      [key]: value,
    };
    dispatch(updateCreditApplication(creditCopyObject));
  };
  const updateErrorFields = (key, value) => {
    errorCopyObject = {
      ...errorCopyObject,
      [key]: value,
    };
    dispatch(updateError(errorCopyObject));
  };
  return (
    <>
      <Styled.SubHeader color={color}>
        {translation('RequiredCardlockProducts')}
      </Styled.SubHeader>
      <CheckBoxField
        checkBoxData={checkBoxData}
        color={color}
        handleCheckBox={(name, value) => updateFields(name, value)}
      />
      <TextField
        type={'number'}
        value={numberOfRequiredCards}
        label={translation('NumberOfRequiredCards')}
        name="numberOfRequiredCards"
        color={color}
        handleTextInput={(name, value) => {
          updateTextFields(name, value);
          setNumberOfRequiredCards(value);
        }}
        handleTextError={(name, value) => {
          updateErrorFields(name, value);
        }}
      />
      <Styled.HorizontalBreak />
    </>
  );
};
const mapStateToProps = (stateData) => {
  return {
    state: stateData,
  };
};
export default connect(mapStateToProps)(RequiredCardlockProducts);
