import creditApplication from '../../constants/creditApplication';
import { ACTION_NAMES } from '../action/action';

const initialState = creditApplication;
export const Reducer = (state = { stateData: initialState }, action) => {
  switch (action.type) {
    case ACTION_NAMES.UPDATE_STATE_CREDIT_APPLICATION:
      return {
        stateData: action.payload,
      };
    default:
      return state;
  }
};
