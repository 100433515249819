import React from 'react';
import * as Styled from './logoStyles';

const Logo = ({ creditLogo, creditText }) => {
  return (
    <Styled.LogoContainer>
      <Styled.LogoImage src={creditLogo}></Styled.LogoImage>
      <Styled.LogoTitle>{creditText}</Styled.LogoTitle>
    </Styled.LogoContainer>
  );
};
export default Logo;
