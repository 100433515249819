import { combineReducers } from 'redux';

import { Reducer } from './reducer';
import { ErrorReducer } from './errorReducer';
import { websiteDataReducer } from './websiteDataReducer';

const rootReducer = combineReducers({
  Reducer,
  ErrorReducer,
  websiteDataReducer,
});

export default rootReducer;
