import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as Styled from './amendmentDateStyles';
import _ from 'lodash';
import RequiredFieldData from '../../constants/requiredFieldData';
import errorData from '../../constants/errorData';
import { updateError } from '../../store/action/errorAction';
import labelData from '../../constants/labelData';
import { useNavigate } from 'react-router-dom';
import { updateWebsiteData } from '../../store/action/websiteDataAction';
import { postFormData } from '../../constants/fetchApi';
import axios from 'axios';
import { aws4Interceptor } from 'aws4-axios';

const AmendmentDate = ({ setLoading }) => {
  const isBrowser = typeof window !== 'undefined';
  const client = axios.create();
  const [translation] = useTranslation();
  const dispatch = useDispatch();
  const typesOfAccountArray = [
    'directDelivery',
    'willCall',
    'pickup',
    'cardlock',
    'onsiteRefueling',
  ];
  const areYou = translation('AreYou');
  const getStoreData = useSelector((state) => state.Reducer.stateData);
  const errorStoreData = useSelector((state) => state.ErrorReducer.errorData);
  const getWebData = useSelector((state) => state.websiteDataReducer.stateData);
  const values = Object.values(errorStoreData);
  let navigate = useNavigate();
  let errorCopyObject = Object.assign(
    {},
    errorStoreData ? errorStoreData : errorData
  );
  const thankYouPath = getWebData.language
    ? `/thankYou?company=${getWebData.companyName}&type=commercial&lang=${getWebData.language}`
    : `/thankYou?company=${getWebData.companyName}&type=commercial`;

  const requiredFields = function (obj1, obj2) {
    return _.reduce(
      obj1,
      function (result, value, key) {
        if (_.isPlainObject(value)) {
          result[key] = requiredFields(value, obj2[key]);
        } else if (_.isEqual(value, obj2[key])) {
          if (typesOfAccountArray.includes(key)) {
            if (
              Object.values(getStoreData.typesOfAccount).every(
                (val) => val === false
              )
            ) {
              result[key] = value;
              errorCopyObject = {
                ...errorCopyObject,
                [key]:
                  translation(labelData[key]) + ' ' + translation('Required'),
              };
              dispatch(updateError(errorCopyObject));
            } else {
              result[key] = value;
              errorCopyObject = {
                ...errorCopyObject,
                [key]: '',
              };
              dispatch(updateError(errorCopyObject));
            }
          } else {
            result[key] = value;
            errorCopyObject = {
              ...errorCopyObject,
              [key]:
                translation(labelData[key]) + ' ' + translation('Required'),
            };
            dispatch(updateError(errorCopyObject));
          }
        } else {
          if (
            errorCopyObject[key] ===
            translation(labelData[key]) + ' ' + translation('Required')
          ) {
            errorCopyObject = {
              ...errorCopyObject,
              [key]: '',
            };
            dispatch(updateError(errorCopyObject));
          }
        }
        return result;
      },
      {}
    );
  };
  const config = {
    headers: {
      'Content-Type': 'text/plain',
      'X-Frame-Options': 'SAMEORIGIN',
      'Strict-Transport-Security':
        'max-age=63072000; includeSubDomains; preload',
      'Content-Security-Policy': 'default-src "self"',
    },
  };
  const raw = { body: getStoreData.verificationCode };
  const handleSubmit = () => {
    const modifiedWebData = {
      ...getWebData,
      isSubmitted: true,
    };
    dispatch(updateWebsiteData(modifiedWebData));
    setLoading(false);
    if (
      Object.keys(errorStoreData).length > 0 &&
      Object.values(errorStoreData).every(
        (value) => value === '' || value === true
      )
    ) {
      const interceptor = aws4Interceptor(
        {
          region: `${process.env.GATSBY_AWS_REGION}`,
          service: 'lambda',
        },
        {
          accessKeyId: `${process.env.GATSBY_AWS_ACCESS_KEY}`,
          secretAccessKey: `${process.env.GATSBY_AWS_SECRET_KEY}`,
        }
      );
      client.interceptors.request.use(interceptor);
      client
        .post(`${process.env.GATSBY_AWS_LAMBDA_URL}`, raw, {
          headers: config.headers,
        })
        .then((res) => {
          if (res.status === 200) {
            postFormData(getStoreData)
              .then((res) => {
                if (res.status === 200) {
                  setLoading(true);
                  navigate(thankYouPath);
                  localStorage.clear();
                }
              })
              .catch((error) => {
                setLoading(true);
              });
          } else {
            throw res?.statusText;
          }
        })
        .catch((error) => {
          setLoading(true);
          errorCopyObject = {
            ...errorCopyObject,
            verificationCode: error?.response?.data?.errors?.captchaResponse,
          };
          dispatch(updateError(errorCopyObject));
        });
    } else {
      setLoading(true);
    }
    requiredFields(RequiredFieldData, getStoreData);
  };
  const handleReset = () => {
    // eslint-disable-next-line no-restricted-globals
    const confirmReset = confirm(areYou);
    if (isBrowser && confirmReset) {
      localStorage.clear();
      window.location.reload();
    }
  };
  return (
    <>
      <Styled.MainDiv>
        <Styled.ButtonDiv>
          <Styled.ButtonStyle type="submit" onClick={() => handleReset()}>
            {translation('Reset')}
          </Styled.ButtonStyle>
          <Styled.ButtonStyle type="submit" onClick={() => handleSubmit()}>
            {translation('Submit')}
          </Styled.ButtonStyle>
        </Styled.ButtonDiv>
        <Styled.Text>{translation('Amended')}</Styled.Text>
      </Styled.MainDiv>
      {Object.values(errorStoreData).every(
        (value) => value === '' || value === false
      ) ? (
        <div></div>
      ) : (
        <Styled.ErrorMsgDiv>
          <Styled.ErrorMsgHeader>
            {translation('ErrorMsg')}
          </Styled.ErrorMsgHeader>
          <Styled.ErrorMsg>
            {values.map((e, index) => (
              <div key={index}>{e}</div>
            ))}
          </Styled.ErrorMsg>
          <Styled.ErrorMsgContact>
            {translation('EmailMsg')}&nbsp;
            <Styled.LinkStyle href="mailto:Commercial.Credit@parkland.ca">
              Commercial.Credit@parkland.ca
            </Styled.LinkStyle>
          </Styled.ErrorMsgContact>
        </Styled.ErrorMsgDiv>
      )}
    </>
  );
};
const mapStateToProps = (stateData) => {
  return {
    state: stateData,
  };
};
export default connect(mapStateToProps)(AmendmentDate);
