import React, { useState } from 'react';
import * as Styled from './accountPayableContactsStyles';
import { useTranslation } from 'react-i18next';
import TextField from '../../common/formSections/textField/textField';
import { connect, useDispatch, useSelector } from 'react-redux';
import creditApplication from '../../constants/creditApplication';
import { updateCreditApplication } from '../../store/action/action';
import { updateError } from '../../store/action/errorAction';
import errorData from '../../constants/errorData';

const AccountPayable = () => {
  const getStoreData = useSelector((state) => state.Reducer.stateData);
  const errorStoreData = useSelector((state) => state.ErrorReducer.errorData);
  const getWebData = useSelector((state) => state.websiteDataReducer.stateData);
  const color = getWebData.appColor;
  const [translation] = useTranslation();
  const dispatch = useDispatch();
  const [name, setName] = useState(
    getStoreData?.accountsPayableContact?.accountsPayableContactName
  );
  const [phone, setPhone] = useState(
    getStoreData?.accountsPayableContact?.accountsPayableContactPhone
  );
  let creditCopyObject = Object.assign(
    {},
    getStoreData ? getStoreData : creditApplication
  );
  let errorCopyObject = Object.assign(
    {},
    errorStoreData ? errorStoreData : errorData
  );
  const updateFields = (key, value) => {
    creditCopyObject['accountsPayableContact'] = {
      ...creditCopyObject['accountsPayableContact'],
      [key]: value,
    };
    dispatch(updateCreditApplication(creditCopyObject));
  };
  const updateErrorFields = (key, value) => {
    errorCopyObject = {
      ...errorCopyObject,
      [key]: value,
    };
    dispatch(updateError(errorCopyObject));
  };
  return (
    <>
      <Styled.SubHeader color={color}>
        {translation('ApplicantsAccountsPayableContact')}
      </Styled.SubHeader>
      <Styled.RowStyle>
        <Styled.ColStyle>
          <TextField
            value={name}
            label={translation('Name')}
            name="accountsPayableContactName"
            type="text"
            color={color}
            handleTextInput={(name, value) => {
              updateFields(name, value);
              setName(value);
            }}
            handleTextError={(name, value) => {
              updateErrorFields(name, value);
            }}
          />
        </Styled.ColStyle>
        <Styled.ColStyle>
          <TextField
            value={phone}
            label={translation('Phone')}
            name="accountsPayableContactPhone"
            type="number"
            color={color}
            handleTextInput={(name, value) => {
              updateFields(name, value);
              setPhone(value);
            }}
            handleTextError={(name, value) => {
              updateErrorFields(name, value);
            }}
          />
        </Styled.ColStyle>
      </Styled.RowStyle>
    </>
  );
};
const mapStateToProps = (stateData) => {
  return {
    state: stateData,
  };
};

export default connect(mapStateToProps)(AccountPayable);
