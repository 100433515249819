import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { AppColors } from '../../constants';

export const SubHeader = styled.h3`
  color: ${(props) => props.color};
  margin: 16px 0 16px 0;
  font-size: 14px;
  font-weight: bold;
  font-family: 'Arial';
`;
export const RowStyle = styled(Row)`
  margin: 0 auto !important;
`;
export const ColStyle = styled(Col)`
  padding-right: 1px !important;
  padding-left: 0 !important;
`;
export const HorizontalBreak = styled.hr`
  border: none;
  border-top: 1px dashed ${AppColors.Gray93};
  margin: 24px 0;
  background-color: ${AppColors.White}!important;
  opacity: 1 !important;
`;
