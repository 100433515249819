import React from 'react';
import SelectField from '../selectField/selectField';
import TextField from '../textField/textField';
import * as Styled from './multiTypeFieldsStyle';

const MultiTypeFields = ({
  multiTypeFieldData,
  color,
  optionList,
  handleMultiType,
  handleError,
  minHeight,
}) => {
  return (
    <Styled.RowStyle>
      {multiTypeFieldData.map((item, index) => (
        <Styled.ColStyle xs={4} key={index}>
          {(item.type === 'text' ||
            item.type === 'postalCode' ||
            item.type === 'email' ||
            item.type === 'number') && (
            <TextField
              minHeight={minHeight}
              value={item.value}
              label={item.label}
              name={item.name}
              type={item.type}
              color={color}
              required={item.required}
              handleTextInput={(key, value) => handleMultiType(key, value)}
              handleTextError={(key, value) => handleError(key, value)}
            />
          )}
          {item.type === 'select' && (
            <SelectField
              value={item.value}
              label={item.label}
              name={item.name}
              color={color}
              optionList={optionList}
              required={item.required}
              handleSelectField={(key, value) => {
                handleMultiType(key, value);
              }}
              handleError={(key, value) => handleError(key, value)}
            />
          )}
        </Styled.ColStyle>
      ))}
    </Styled.RowStyle>
  );
};
export default MultiTypeFields;
