import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import { AppColors } from '../../../constants';

export const RowStyle = styled(Row)`
  margin: 0 auto !important;
`;
export const ColStyle = styled(Col)`
  padding-right: 1px !important;
  padding-left: 0 !important;
`;

export const Label = styled.label`
  color: ${(props) => props.color};
  background: ${AppColors.Gray93};
  display: block;
  width: 100%;
  min-height: 31px;
  margin-bottom: 1px;
  padding: 8px;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
  clear: both;
  font-family: Arial;
`;

export const InputField = styled.input`
  padding: 0;
  height: auto;
  border: none;
  width: auto;
  float: left;
  margin-right: 12px;
  margin-bottom: 2px;
  background-color: ${AppColors.White};
  color: ${AppColors.Gray};
`;
