import styled from 'styled-components';
import { AppColors } from '../../constants';

export const HeaderLayout = styled.h2`
  font-family: 'Arial';
  color: ${(props) => props.color};
  font-size: 24px;
  text-transform: uppercase;
  margin: 16px 0 16px 0;
  font-weight: bold;
`;
export const ParaText = styled.p`
  font-family: 'Arial';
  color: ${AppColors.Gray};
  font-size: 14px;
  font-style: italic;
`;
export const DateContainer = styled.label`
  background-color: ${AppColors.Gray93};
  width: 25%;
  padding: 8px;
  text-align: left;
  clear: both;
  font-family: Arial;
  font-size: 12px;
  color: ${(props) => props.color};
`;
export const InputField = styled.input`
  width: -webkit-fill-available;
  background-color: ${AppColors.Gray93};
  border: 1px solid lightgray;
  font-family: Arial;
  font-size: 12px;
  padding: 5px;
  color: ${(props) => props.color};
`;
