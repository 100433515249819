const creditApplication = {
  generalInformation: {
    registeredCompanyName: '',
    operatingName: '',
    gst: '',
    hst: '',
    pst: '',
    closestBranch: '',
    applicantIsA: '',
    generalInformationOther: '',
    dateApplicantWasEstablished: '',
    applicantIsaDivisionOf: '',
    natureOfApplicantsBusiness: '',
    operatingUnderCurrentOwnership: '',
  },
  billingMethod: '',
  billingAddress: {
    emailAddress: '',
    streetAddress: '',
    city: '',
    province: '',
    billingAddressPostalCode: '',
  },
  deliveryAddress: {
    streetAddress: '',
    city: '',
    province: '',
    deliveryAddressPostalCode: '',
  },
  contactInfo: {
    phone: '',
    cellPhone: '',
    fax: '',
  },
  yearEnd: {
    financialStatements: '',
    financialYearEndDate: '',
  },
  accountsPayableContact: {
    accountsPayableContactName: '',
    accountsPayableContactPhone: '',
  },
  applicantsPartners: {
    applicantsPartnersFullName1: '',
    applicantsPartnersPhone1: '',
    applicantsPartnersPositions1: '',
    applicantsPartnersDateOfBirth1: '',
    applicantsPartnersSin1: '',
    applicantsPartnersFullName2: '',
    applicantsPartnersPhone2: '',
    applicantsPartnersPositions2: '',
    applicantsPartnersDateOfBirth2: '',
    applicantsPartnersSin2: '',
    applicantsPartnersFullName3: '',
    applicantsPartnersPhone3: '',
    applicantsPartnersPositions3: '',
    applicantsPartnersDateOfBirth3: '',
    applicantsPartnersSin3: '',
  },
  tradeReferences: {
    tradeReferencesCompanyName1: '',
    tradeReferencesName1: '',
    tradeReferencesPhone1: '',
    tradeReferencesCompanyName2: '',
    tradeReferencesName2: '',
    tradeReferencesPhone2: '',
    tradeReferencesCompanyName3: '',
    tradeReferencesName3: '',
    tradeReferencesPhone3: '',
  },
  bankInformation: {
    bank: '',
    account: '',
    branch: '',
    bankInformationAddress: '',
    bankInformationName: '',
    bankInformationPhone: '',
  },
  productsAccountInformation: {
    existingAccount: '',
    providerAccount: '',
  },
  typesOfAccount: {
    directDelivery: false,
    willCall: false,
    pickup: false,
    cardlock: false,
    onsiteRefueling: false,
  },
  requiredPetroleumProducts: {
    diesel: false,
    markedDiesel: false,
    gas: false,
    markedGas: false,
    propane: false,
    bioDiesel: false,
    agriculturalProducts: false,
    naturalGas: false,
    heatingOil: false,
    lubricants: false,
    marine: false,
    aviation: false,
  },
  requiredPetroleumProductsOther: '',
  cardlockProducts: {
    diesel: false,
    markedDiesel: false,
    gas: false,
    markedGas: false,
    propane: false,
  },
  numberOfRequiredCards: '',
  refuelingServiceInformation: {
    noOfTrucks: '',
    additionalEquipment: '',
    serviceStartDate: '',
  },
  petroleumType: '',
  scheduling: {
    scheduling: '',
    productsAccountInformationOther: '',
    noOfDays: '',
    purchaseExpected: '',
    litersExpected: '',
  },

  taxExemptions: {
    exceptionStatus: '',
    offRoad: '',
    fishingException: '',
    other: '',
  },
  accountRequirements: {
    creditLimit: '',
    statementRequired: '',
    purchaseOrders: '',
    otherSpecifications: '',
  },
  iAgree: false,
  verificationCode: '',
  type: '',
  organization: '',
  language: '',
};

export default creditApplication;
