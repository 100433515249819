import React, { useState } from 'react';
import * as Styled from './eBillingStyles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateCreditApplication } from '../../store/action/action';
import creditApplication from '../../constants/creditApplication';
import RadioToggleField from '../../common/formSections/radioToggleField/radioToggleField';
import RadioField from '../../common/formSections/radioField/radioField';

const EBilling = () => {
  const [translation] = useTranslation();
  const getWebData = useSelector((state) => state.websiteDataReducer.stateData);
  const color = getWebData.appColor;

  const dispatch = useDispatch();
  const getStoreData = useSelector((state) => state.Reducer.stateData);
  const billingMethods = [
    {
      label: translation('Yes'),
      required: 'required',
      value: getStoreData?.billingMethod.includes(translation('Yes'))
        ? true
        : false,
    },
    {
      label: translation('No'),
      required: 'required',
      value: getStoreData?.billingMethod.includes(translation('No'))
        ? true
        : false,
    },
  ];
  const [eBilling, setEBilling] = useState(getStoreData?.billingMethod);
  let creditCopyObject = Object.assign(
    {},
    getStoreData ? getStoreData : creditApplication
  );
  const updateFields = (key, value) => {
    creditCopyObject[key] = value;
    dispatch(updateCreditApplication(creditCopyObject));
  };
  return (
    <>
      <Styled.SubHeader color={color}>
        {translation('EBilling')}
      </Styled.SubHeader>
      <Styled.NoteStyle>
        <Styled.Note>{translation('Note')}</Styled.Note>
        {translation('PreferredBillingMethod')}
      </Styled.NoteStyle>
      <RadioField
        value={eBilling}
        color={color}
        radioData={billingMethods}
        name="billingMethod"
        handleRadioButton={(name, value) => updateFields(name, value)}
        width="6"
      />
    </>
  );
};
export default EBilling;
