import React, { useState } from 'react';
import * as Styled from './deliveryAddressStyles';
import TextField from '../../common/formSections/textField/textField';
import { useTranslation } from 'react-i18next';
import MultiTypeFields from '../../common/formSections/multiTypeFields/multiTypeFields';
import { province, provincefr } from '../../constants/fieldData';
import { connect, useDispatch, useSelector } from 'react-redux';
import { updateCreditApplication } from '../../store/action/action';
import creditApplication from '../../constants/creditApplication';
import { updateError } from '../../store/action/errorAction';
import errorData from '../../constants/errorData';

const DeliveryAddress = () => {
  const [translation] = useTranslation();
  const dispatch = useDispatch();
  const getStoreData = useSelector((state) => state.Reducer.stateData);
  const errorStoreData = useSelector((state) => state.ErrorReducer.errorData);
  const getWebData = useSelector((state) => state.websiteDataReducer.stateData);
  const color = getWebData.appColor;
  const language = getWebData.language;

  const multiTypeFieldData = [
    {
      label: translation('City'),
      type: 'text',
      name: 'city',
      value: getStoreData?.deliveryAddress?.city,
    },
    {
      label: translation('Province'),
      type: 'select',
      name: 'province',
      value: getStoreData?.deliveryAddress?.province,
    },
    {
      label: translation('PostalCode'),
      type: 'postalCode',
      name: 'deliveryAddressPostalCode',
      value: getStoreData?.deliveryAddress?.deliveryAddressPostalCode,
    },
  ];
  const [streetAddress, setStreetAddress] = useState(
    getStoreData?.deliveryAddress?.streetAddress
  );
  const [city, setCity] = useState(getStoreData?.deliveryAddress?.city);
  const [provinceDropDown, setProvinceDropDown] = useState(
    getStoreData?.deliveryAddress?.province
  );
  const [deliveryAddressPostalCode, setDeliveryAddressPostalCode] = useState(
    getStoreData?.deliveryAddress?.deliveryAddressPostalCode
  );
  let creditCopyObject = Object.assign(
    {},
    getStoreData ? getStoreData : creditApplication
  );
  let errorCopyObject = Object.assign(
    {},
    errorStoreData ? errorStoreData : errorData
  );
  const updateFields = (key, value) => {
    creditCopyObject['deliveryAddress'] = {
      ...creditCopyObject['deliveryAddress'],
      [key]: value,
    };
    dispatch(updateCreditApplication(creditCopyObject));
  };
  const updateErrorFields = (key, value) => {
    errorCopyObject = {
      ...errorCopyObject,
      [key]: value,
    };
    dispatch(updateError(errorCopyObject));
  };
  const provinceList = language === 'en-US' ? province : provincefr;
  return (
    <>
      <Styled.SubHeader color={color}>
        {translation('ApplicantsDeliveryAddress')}
      </Styled.SubHeader>

      <TextField
        value={streetAddress}
        label={translation('StreetAddress')}
        name="streetAddress"
        type="text"
        color={color}
        handleTextInput={(name, value) => {
          updateFields(name, value);
          setStreetAddress(value);
        }}
        handleTextError={(name, value) => {
          updateErrorFields(name, value);
        }}
      />

      <MultiTypeFields
        multiTypeFieldData={multiTypeFieldData}
        color={color}
        optionList={provinceList}
        handleMultiType={(name, value) => {
          updateFields(name, value);
          name === 'city'
            ? setCity(value)
            : name === 'province'
            ? setProvinceDropDown(value)
            : setDeliveryAddressPostalCode(value);
        }}
        handleError={(name, value) => {
          updateErrorFields(name, value);
        }}
      />
      <Styled.HorizontalBreak />
    </>
  );
};
const mapStateToProps = (stateData) => {
  return {
    state: stateData,
  };
};

export default connect(mapStateToProps)(DeliveryAddress);
