import React, { useState } from 'react';
import * as Styled from './bankInformationStyles';
import { useTranslation } from 'react-i18next';
import MultiTypeFields from '../../common/formSections/multiTypeFields/multiTypeFields';
import TextField from '../../common/formSections/textField/textField';
import TextAreaField from '../../common/formSections/textAreaField/textAreaField';
import { connect, useDispatch, useSelector } from 'react-redux';
import creditApplication from '../../constants/creditApplication';
import { updateCreditApplication } from '../../store/action/action';
import errorData from '../../constants/errorData';
import { updateError } from '../../store/action/errorAction';

const BankInformation = () => {
  const [translation] = useTranslation();
  const dispatch = useDispatch();
  const getStoreData = useSelector((state) => state.Reducer.stateData);
  const errorStoreData = useSelector((state) => state.ErrorReducer.errorData);
  const getWebData = useSelector((state) => state.websiteDataReducer.stateData);
  const color = getWebData.appColor;
  const multiTypeFieldData = [
    {
      label: translation('Bank'),
      type: 'text',
      name: 'bank',
      required: 'required',
      value: getStoreData?.bankInformation?.bank,
    },
    {
      label: translation('Account'),
      type: 'text',
      name: 'account',
      required: 'required',
      value: getStoreData?.bankInformation?.account,
    },
    {
      label: translation('Branch'),
      type: 'text',
      name: 'branch',
      required: 'required',
      value: getStoreData?.bankInformation?.branch,
    },
  ];
  const [bank, setBank] = useState(getStoreData?.bankInformation?.bank);
  const [account, setAccount] = useState(
    getStoreData?.bankInformation?.account
  );
  const [branch, setBranch] = useState(getStoreData?.bankInformation?.branch);
  const [bankInformationAddress, setBankInformationAddress] = useState(
    getStoreData?.bankInformation?.bankInformationAddress
  );
  const [bankInformationPhone, setBankInformationPhone] = useState(
    getStoreData?.bankInformation?.bankInformationPhone
  );
  const [bankInformationName, setBankInformationName] = useState(
    getStoreData?.bankInformation?.bankInformationName
  );

  let creditCopyObject = Object.assign(
    {},
    getStoreData ? getStoreData : creditApplication
  );
  let errorCopyObject = Object.assign(
    {},
    errorStoreData ? errorStoreData : errorData
  );
  const updateFields = (key, value) => {
    creditCopyObject['bankInformation'] = {
      ...creditCopyObject['bankInformation'],
      [key]: value,
    };
    dispatch(updateCreditApplication(creditCopyObject));
  };
  const updateErrorFields = (key, value) => {
    errorCopyObject = {
      ...errorCopyObject,
      [key]: value,
    };
    dispatch(updateError(errorCopyObject));
  };
  return (
    <>
      <Styled.HeaderLayout color={color}>
        {translation('ApplicantsBankInformation')}
      </Styled.HeaderLayout>
      <MultiTypeFields
        multiTypeFieldData={multiTypeFieldData}
        color={color}
        handleMultiType={(name, value) => {
          updateFields(name, value);
          name === 'bank'
            ? setBank(value)
            : name === 'branch'
            ? setBranch(value)
            : setAccount(value);
        }}
        handleError={(name, value) => {
          updateErrorFields(name, value);
        }}
      />
      <TextAreaField
        value={bankInformationAddress}
        label={translation('Address')}
        name="bankInformationAddress"
        type="text"
        color={color}
        required="required"
        handleTextInput={(name, value) => {
          updateFields(name, value);
          setBankInformationAddress(value);
        }}
        handleTextError={(name, value) => {
          updateErrorFields(name, value);
        }}
      />
      <Styled.RowStyle>
        <Styled.ColStyle>
          <TextField
            value={bankInformationName}
            label={translation('Name')}
            name="bankInformationName"
            type="text"
            color={color}
            handleTextInput={(name, value) => {
              updateFields(name, value);
              setBankInformationName(value);
            }}
            handleTextError={(name, value) => {
              updateErrorFields(name, value);
            }}
          />
        </Styled.ColStyle>
        <Styled.ColStyle>
          <TextField
            value={bankInformationPhone}
            label={translation('Phone')}
            name="bankInformationPhone"
            type="number"
            color={color}
            handleTextInput={(name, value) => {
              updateFields(name, value);
              setBankInformationPhone(value);
            }}
            handleTextError={(name, value) => {
              updateErrorFields(name, value);
            }}
          />
        </Styled.ColStyle>
      </Styled.RowStyle>
    </>
  );
};
const mapStateToProps = (stateData) => {
  return {
    state: stateData,
  };
};

export default connect(mapStateToProps)(BankInformation);
