import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AppColors } from '../../../constants';
import { isNotNull } from '../../../utils/validations';
import * as Styled from './checkBoxFieldStyle';

const CheckBoxField = ({
  checkBoxData,
  color,
  handleCheckBox,
  handleError,
  width,
}) => {
  const [checkedState, setCheckedState] = useState(checkBoxData);
  const getWebData = useSelector((state) => state.websiteDataReducer.stateData);
  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? { ...item, value: !item.value } : item
    );
    setCheckedState(updatedCheckedState);
  };
  let isNull = true;

  return (
    <Styled.RowStyle>
      {checkedState.map(
        (item, index) => (
          checkedState.map(
            (data) => (isNull = isNull && !isNotNull(data.value))
          ),
          (
            <Styled.ColStyle xs={width} key={index}>
              <Styled.Label
                color={
                  item.required && getWebData.isSubmitted && isNull
                    ? AppColors.Red
                    : color
                }
              >
                {item.required ? '* ' + item.label : item.label}
                <Styled.InputField
                  name={item.name}
                  checked={item.value}
                  type="checkbox"
                  onChange={() => {
                    handleOnChange(index);
                  }}
                  onBlur={(e) => {
                    item.required && isNull
                      ? checkedState.map((item) =>
                          handleError?.(item.name, item.label + ' required')
                        )
                      : checkedState.map((item) =>
                          handleError?.(item.name, '')
                        );
                    handleCheckBox(item.name, item.value ? item.label : false);
                  }}
                />
              </Styled.Label>
            </Styled.ColStyle>
          )
        )
      )}
    </Styled.RowStyle>
  );
};

export default CheckBoxField;
