export const validateForm = (
  name,
  type,
  input,
  ref,
  handleTextError,
  required,
  label,
  updateErrorMessage,
  translation
) => {
  switch (type) {
    case 'text':
      if (required && input.value === '') {
        ref.current.style.border = '1px solid red';
        ref.current.style.backgroundColor = '#FCDFE0';
        handleTextError(name, label + ' ' + translation('Required'));
        updateErrorMessage(translation('Required'));
      } else {
        if (input.value.match(/%|\\|<|>|www|http|php\?/i)) {
          ref.current.style.border = '1px solid red';
          ref.current.style.backgroundColor = '#FCDFE0';
          handleTextError(name, label + ' ' + translation('IsInvalid'));
          updateErrorMessage(translation('Invalid'));
        } else {
          ref.current.style.border = '1px solid lightgrey';
          ref.current.style.backgroundColor = '#FFF';
          handleTextError(name, '');
          updateErrorMessage('');
        }
      }
      break;
    case 'email':
      if (required && input.value === '') {
        ref.current.style.border = '1px solid red';
        ref.current.style.backgroundColor = '#FCDFE0';
        handleTextError(name, label + ' ' + translation('Required'));
        updateErrorMessage(translation('Required'));
      } else {
        const atPos = input.value.indexOf('@');
        const dotPos = input.value.lastIndexOf('.');
        const ats = input.value.match(/@/gi);
        const spaces = input.value.match(/\s/gi);
        if (atPos < 1 || dotPos - atPos < 2 || ats.length > 1 || spaces) {
          ref.current.style.border = '1px solid red';
          ref.current.style.backgroundColor = '#FCDFE0';
          handleTextError(name, label + ' ' + translation('IsInvalid'));
          updateErrorMessage(translation('Invalid'));
        } else {
          ref.current.style.border = '1px solid lightgrey';
          ref.current.style.backgroundColor = '#FFF';
          handleTextError(name, '');
          updateErrorMessage('');
        }
      }
      break;
    case 'postalCode':
      if (required && input.value === '') {
        ref.current.style.border = '1px solid red';
        ref.current.style.backgroundColor = '#FCDFE0';
        handleTextError(name, label + ' ' + translation('Required'));
        updateErrorMessage(translation('Required'));
      } else {
        if (
          !input.value.match(/[a-z][0-9][a-z][\s]?[0-9][a-z][0-9]/i) &&
          input.value !== ''
        ) {
          ref.current.style.border = '1px solid red';
          ref.current.style.backgroundColor = '#FCDFE0';
          handleTextError(name, label + ' ' + translation('IsInvalid'));
          updateErrorMessage(translation('Invalid'));
        } else {
          ref.current.style.border = '1px solid lightgrey';
          ref.current.style.backgroundColor = '#FFF';
          handleTextError(name, '');
          updateErrorMessage('');
        }
      }
      break;
    case 'number':
      if (required && input.value === '') {
        ref.current.style.border = '1px solid red';
        ref.current.style.backgroundColor = '#FCDFE0';
        handleTextError(name, label + ' ' + translation('Required'));
        updateErrorMessage(translation('Required'));
      } else {
        if (!input.value.match('^[0-9]+$') && input.value !== '') {
          ref.current.style.border = '1px solid red';
          ref.current.style.backgroundColor = '#FCDFE0';
          handleTextError(name, label + ' ' + translation('IsInvalid'));
          updateErrorMessage(translation('Invalid'));
        } else {
          ref.current.style.border = '1px solid lightgrey';
          ref.current.style.backgroundColor = '#FFF';
          handleTextError(name, '');
          updateErrorMessage('');
        }
      }
      break;
    case 'date':
      if (required && input === 'Invalid date') {
        //ref.current.style.border = '1px solid red';
        //ref.current.style.backgroundColor = '#FCDFE0';
        handleTextError(name, label + ' ' + translation('IsInvalid'));
        updateErrorMessage(translation('Invalid'));
      } else {
        //ref.current.style.border = '1px solid lightgrey';
        //ref.current.style.backgroundColor = '#FFF';
        handleTextError(name, '');
        updateErrorMessage('');
      }
      break;
    default:
      break;
  }
};

export const isNotNull = (value) => {
  return !!value;
};
