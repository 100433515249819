export const closestBranch = [
  '- Select One -',
  'AB :: Bonnyville',
  'AB :: Calgary',
  'AB :: Crossfield',
  'AB :: Drayton Valley',
  'AB :: Edmonton',
  'AB :: Edson',
  'AB :: Fort McMurray',
  'AB :: Grande Prairie',
  'AB :: High Level',
  'AB :: La Crete',
  'AB :: Lloydminster',
  'AB :: Olds',
  'AB :: Peace River',
  'AB :: Rainbow Lake',
  'AB :: Red Earth Creek',
  'AB :: Slave Lake',
  'AB :: Taber',
  'AB :: Whitecourt',
  'BC :: Cranbrook',
  'BC :: Creston',
  'BC :: Dawson Creek',
  'BC :: Fort Nelson',
  'BC :: Fort St. John',
  'MB :: Winnipeg',
  'NB :: Moncton',
  'NS :: Amherst',
  'NS :: Berwick',
  'NS :: Bridgewater',
  'NS :: Cheticamp',
  'NS :: Country Harbour',
  'NS :: Dartmouth',
  'NS :: Digby',
  'NS :: St. Peters',
  'NS :: Stellarton',
  'NS :: Sydney',
  'NS :: Truro',
  'NS :: Yarmouth',
  'NT :: Hay River',
  'NT :: Yellowknife',
  'ON :: Bancroft',
  'ON :: Harriston',
  'ON :: Kenora',
  'ON :: London',
  'ON :: Minden',
  'ON :: New Liskeard',
  'ON :: North Bay',
  'ON :: Ottawa',
  'ON :: Rainy River',
  'ON :: Renfrew',
  'ON :: Schreiber',
  'ON :: St. Catharines',
  'ON :: Sudbury',
  'ON :: Thunder Bay',
  'ON :: Toronto',
  'SK :: Estevan',
  'SK :: Kindersley',
  'SK :: La Ronge',
  'SK :: Oxbow',
  'SK :: Saskatoon',
  'SK :: Weyburn',
  'YT :: Whitehorse',
  'OTHER',
];

export const closestBranchfr = [
  '- Sélectionnez-en une -',
  'AB :: Bonnyville',
  'AB :: Calgary',
  'AB :: Crossfield',
  'AB :: Drayton Valley',
  'AB :: Edmonton',
  'AB :: Edson',
  'AB :: Fort McMurray',
  'AB :: Grande Prairie',
  'AB :: High Level',
  'AB :: La Crete',
  'AB :: Lloydminster',
  'AB :: Olds',
  'AB :: Peace River',
  'AB :: Rainbow Lake',
  'AB :: Red Earth Creek',
  'AB :: Slave Lake',
  'AB :: Taber',
  'AB :: Whitecourt',
  'BC :: Cranbrook',
  'BC :: Creston',
  'BC :: Dawson Creek',
  'BC :: Fort Nelson',
  'BC :: Fort St. John',
  'MB :: Winnipeg',
  'NB :: Moncton',
  'NS :: Amherst',
  'NS :: Berwick',
  'NS :: Bridgewater',
  'NS :: Cheticamp',
  'NS :: Country Harbour',
  'NS :: Dartmouth',
  'NS :: Digby',
  'NS :: St. Peters',
  'NS :: Stellarton',
  'NS :: Sydney',
  'NS :: Truro',
  'NS :: Yarmouth',
  'NT :: Hay River',
  'NT :: Yellowknife',
  'ON :: Bancroft',
  'ON :: Harriston',
  'ON :: Kenora',
  'ON :: London',
  'ON :: Minden',
  'ON :: New Liskeard',
  'ON :: North Bay',
  'ON :: Ottawa',
  'ON :: Rainy River',
  'ON :: Renfrew',
  'ON :: Schreiber',
  'ON :: St. Catharines',
  'ON :: Sudbury',
  'ON :: Thunder Bay',
  'ON :: Toronto',
  'SK :: Estevan',
  'SK :: Kindersley',
  'SK :: La Ronge',
  'SK :: Oxbow',
  'SK :: Saskatoon',
  'SK :: Weyburn',
  'YT :: Whitehorse',
  'OTHER',
];

export const closestBranchIsland = [
  '- Select One -',
  'Charlottetown',
  'Summerside',
];

export const closestBranchIslandfr = [
  '- Sélectionnez-en une -',
  'Charlottetown',
  'Summerside',
];

export const closestBranchUltramar = [
  '- Select One -',
  'NB :: Moncton ',
  'NL :: Corner Brook',
  'NL :: Grand Falls',
  'NL :: Marystown',
  "NL :: St. John's",
  'NS :: Amherst',
  'NS :: Bridgewater',
  'NS :: Cheticamp',
  'NS :: Dartmouth',
  'NS :: Digby',
  'NS :: Kentville',
  'NS :: St. Peters',
  'NS :: Stellarton',
  'NS :: Sydney',
  'NS :: Truro',
  'NS :: Yarmouth',
  'ON :: Belleville',
  'ON :: Cornwall',
  'ON :: Haliburton',
  'ON :: Lindsay',
  'ON :: Mount Albert',
  'ON :: Ottawa',
  'ON :: Sudbury',
];

export const closestBranchUltramarfr = [
  '- Sélectionnez-en une -',
  'NB : : Moncton ',
  'NL : : Corner Brook',
  'NL : : Grand Falls',
  'NL : : Marystown',
  "NL : : St. John's",
  'NS : : Amherst',
  'NS : : Bridgewater',
  'NS : : Chéticamp',
  'NS : : Dartmouth',
  'NS : : Digby',
  'NS : : Kentville',
  'NS : : St. Peters',
  'NS : : Stellarton',
  'NS : : Sydney',
  'NS : : Truro',
  'NS : : Yarmouth',
  'ON : : Belleville',
  'ON : : Cornwall',
  'ON : : Haliburton',
  'ON : : Lindsay',
  'ON : : Mount Albert',
  'ON : : Ottawa',
  'ON : : Sudbury',
];

export const closestBranchSparlingsfr = [
  'Sélectionnez-en un',
  'Bancroft',
  'Bracebridge',
  'Brechin',
  'Blyth',
  'Flesherton',
  'North Bay',
  'Saint-Georges',
  'Wiarton',
];

export const closestBranchSparlings = [
  '- Select One -',
  'Bancroft',
  'Bracebridge',
  'Brechin',
  'Blyth',
  'Flesherton',
  'North Bay',
  'St. George',
  'Wiarton',
];

export const closestBranchColumbia = [
  '- Select One -',
  'Bella Coola',
  'Campbell River',
  'Duncan',
  'Kamloops',
  'Nanaimo',
  'Pitt Meadows',
  'Powell River',
  'Prince George',
  'Sechelt',
  'Ucluelet',
  'Victoria',
];

export const closestBranchColumbiafr = [
  'Sélectionnez-en un',
  'Bella Coola',
  'Campbell River',
  'Duncan',
  'Kamloops',
  'Nanaimo',
  'Pitt Meadows',
  'Powell River',
  'Prince George',
  'Sechelt',
  'Ucluelet',
  'Victoria',
];

export const province = [
  '- Select One -',
  'British Columbia',
  'Alberta',
  'Saskatchewan',
  'Manitoba',
  'Ontario',
  'Quebec',
  'Newfoundland and Labrador',
  'New Brunswick',
  'Nova Scotia',
  'Prince Edward Island',
  'Yukon',
  'Northwest Territories',
  'Nunavut',
];

export const provincefr = [
  '- Sélectionnez-en une -',
  'Colombie-Britannique',
  'Alberta',
  'Saskatchewan',
  'Manitoba',
  'Ontario',
  'Québec',
  'Terre-Neuve-et-Labrador',
  'Nouveau-Brunswick',
  'Nouvelle-Écosse',
  'Île-du-Prince-Édouard',
  'Yukon',
  'Territoires du Nord-Ouest',
  'Nunavut',
];
