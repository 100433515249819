import styled from 'styled-components';

export const HeaderLayout = styled.h2`
  font-family: 'Arial';
  color: ${(props) => props.color};
  font-size: 21px;
  margin: 16px 0 16px 0;
  font-weight: bold;
`;

export const SubHeader = styled.h3`
  color: ${(props) => props.color};
  margin: 16px 0 16px 0;
  font-size: 14px;
  font-weight: bold;
  font-family: 'Arial';
`;
