import React, { useState } from 'react';
import * as Styled from './petroleumProductsAccountInfoStyles';
import { useTranslation } from 'react-i18next';
import TextField from '../../common/formSections/textField/textField';
import { connect, useDispatch, useSelector } from 'react-redux';
import creditApplication from '../../constants/creditApplication';
import { updateCreditApplication } from '../../store/action/action';
import { updateError } from '../../store/action/errorAction';
import errorData from '../../constants/errorData';
import RadioField from '../../common/formSections/radioField/radioField';

const PetroleumProductsAccountInfo = () => {
  const [translation] = useTranslation();
  const getWebData = useSelector((state) => state.websiteDataReducer.stateData);
  const color = getWebData.appColor;
  const dispatch = useDispatch();
  const getStoreData = useSelector((state) => state.Reducer.stateData);
  const errorStoreData = useSelector((state) => state.ErrorReducer.errorData);
  const AccountInformation = [
    {
      label: translation('ApplicantYearEndYes'),
      required: 'required',
      value: getStoreData?.productsAccountInformation?.existingAccount.includes(
        translation('ApplicantYearEndYes')
      )
        ? true
        : false,
    },
    {
      label: translation('ApplicantYearEndNo'),
      required: 'required',
      value: getStoreData?.productsAccountInformation?.existingAccount.includes(
        translation('ApplicantYearEndNo')
      )
        ? true
        : false,
    },
  ];
  const [accountExist, setAccountExist] = useState(
    getStoreData.productsAccountInformation.existingAccount
  );
  const [providerAccount, setProviderAccount] = useState(
    getStoreData.productsAccountInformation.providerAccount
  );
  let creditCopyObject = Object.assign(
    {},
    getStoreData ? getStoreData : creditApplication
  );
  let errorCopyObject = Object.assign(
    {},
    errorStoreData ? errorStoreData : errorData
  );
  const updateFields = (key, value) => {
    creditCopyObject['productsAccountInformation'] = {
      ...creditCopyObject['productsAccountInformation'],
      [key]: value,
    };
    dispatch(updateCreditApplication(creditCopyObject));
  };
  const updateErrorFields = (key, value) => {
    errorCopyObject = {
      ...errorCopyObject,
      [key]: value,
    };
    dispatch(updateError(errorCopyObject));
  };
  return (
    <>
      <Styled.HeaderLayout color={color}>
        {translation('ApplicantsPetroleumProducts')}
      </Styled.HeaderLayout>
      <Styled.SubHeader color={color}>
        {translation('ExistingPetroleumAccount')}
      </Styled.SubHeader>
      <RadioField
        value={accountExist}
        radioData={AccountInformation}
        name="existingAccount"
        color={color}
        width="6"
        handleRadioButton={(name, value) => updateFields(name, value)}
      />
      <TextField
        value={providerAccount}
        label={translation('YesProvideAccount')}
        name="providerAccount"
        type="text"
        color={color}
        handleTextInput={(name, value) => {
          updateFields(name, value);
          setProviderAccount(value);
        }}
        handleTextError={(name, value) => {
          updateErrorFields(name, value);
        }}
      />
    </>
  );
};
const mapStateToProps = (stateData) => {
  return {
    state: stateData,
  };
};

export default connect(mapStateToProps)(PetroleumProductsAccountInfo);
