import React, { useState } from 'react';
import * as Styled from './tradeReferencesStyles';
import { useTranslation } from 'react-i18next';
import TextField from '../../common/formSections/textField/textField';
import { connect, useDispatch, useSelector } from 'react-redux';
import creditApplication from '../../constants/creditApplication';
import { updateCreditApplication } from '../../store/action/action';
import errorData from '../../constants/errorData';
import { updateError } from '../../store/action/errorAction';

const TradeReferences = () => {
  const [translation] = useTranslation();
  let index = 0;
  const dispatch = useDispatch();
  const getStoreData = useSelector((state) => state.Reducer.stateData);
  const errorStoreData = useSelector((state) => state.ErrorReducer.errorData);
  const getWebData = useSelector((state) => state.websiteDataReducer.stateData);
  const color = getWebData.appColor;
  const multiTypeFieldData = [
    [
      {
        label: translation('CompanyName'),
        name: 'tradeReferencesCompanyName1',
        type: 'text',
        value: getStoreData?.tradeReferences?.tradeReferencesCompanyName1,
      },
      {
        label: translation('Name'),
        name: 'tradeReferencesName1',
        type: 'text',
        value: getStoreData?.tradeReferences?.tradeReferencesName1,
      },

      {
        label: translation('Phone'),
        name: 'tradeReferencesPhone1',
        type: 'number',
        value: getStoreData?.tradeReferences?.tradeReferencesPhone1,
      },
    ],
    [
      {
        label: translation('CompanyName'),
        name: 'tradeReferencesCompanyName2',
        type: 'text',
        value: getStoreData?.tradeReferences?.tradeReferencesCompanyName2,
      },
      {
        label: translation('Name'),
        name: 'tradeReferencesName2',
        type: 'text',
        value: getStoreData?.tradeReferences?.tradeReferencesName2,
      },

      {
        label: translation('Phone'),
        name: 'tradeReferencesPhone2',
        type: 'number',
        value: getStoreData?.tradeReferences?.tradeReferencesPhone2,
      },
    ],
    [
      {
        label: translation('CompanyName'),
        name: 'tradeReferencesCompanyName3',
        type: 'text',
        value: getStoreData?.tradeReferences?.tradeReferencesCompanyName3,
      },
      {
        label: translation('Name'),
        name: 'tradeReferencesName3',
        type: 'text',
        value: getStoreData?.tradeReferences?.tradeReferencesName3,
      },

      {
        label: translation('Phone'),
        name: 'tradeReferencesPhone3',
        type: 'number',
        value: getStoreData?.tradeReferences?.tradeReferencesPhone3,
      },
    ],
  ];
  const [tradeReferencesCompanyName1, setTradeReferencesCompanyName1] =
    useState(getStoreData?.tradeReferences?.tradeReferencesCompanyName1);
  const [tradeReferencesCompanyName2, setTradeReferencesCompanyName2] =
    useState(getStoreData?.tradeReferences?.tradeReferencesCompanyName2);
  const [tradeReferencesCompanyName3, setTradeReferencesCompanyName3] =
    useState(getStoreData?.tradeReferences?.tradeReferencesCompanyName2);
  const [tradeReferencesName1, setTradeReferencesName1] = useState(
    getStoreData?.tradeReferences?.tradeReferencesName1
  );
  const [tradeReferencesName2, setTradeReferencesName2] = useState(
    getStoreData?.tradeReferences?.tradeReferencesName2
  );
  const [tradeReferencesName3, setTradeReferencesName3] = useState(
    getStoreData?.tradeReferences?.tradeReferencesName1
  );
  const [tradeReferencesPhone1, setTradeReferencesPhone1] = useState(
    getStoreData?.tradeReferences?.tradeReferencesPhone1
  );
  const [tradeReferencesPhone2, setTradeReferencesPhone2] = useState(
    getStoreData?.tradeReferences?.tradeReferencesPhone3
  );
  const [tradeReferencesPhone3, setTradeReferencesPhone3] = useState(
    getStoreData?.tradeReferences?.tradeReferencesPhone3
  );
  let creditCopyObject = Object.assign(
    {},
    getStoreData ? getStoreData : creditApplication
  );
  let errorCopyObject = Object.assign(
    {},
    errorStoreData ? errorStoreData : errorData
  );
  const updateFields = (key, value) => {
    creditCopyObject['tradeReferences'] = {
      ...creditCopyObject['tradeReferences'],
      [key]: value,
    };
    dispatch(updateCreditApplication(creditCopyObject));
  };
  const updateErrorFields = (key, value) => {
    errorCopyObject = {
      ...errorCopyObject,
      [key]: value,
    };
    dispatch(updateError(errorCopyObject));
  };
  return (
    <>
      <Styled.HeaderLayout color={color}>
        {translation('ApplicantsTradeReferences')}
      </Styled.HeaderLayout>
      {multiTypeFieldData.map(
        (item) => (
          index++,
          (
            <Styled.RowStyle key={index}>
              <Styled.ColStyle xs={'auto'}>
                <Styled.InputContainer color={color}>
                  {`(${index})`}
                </Styled.InputContainer>
              </Styled.ColStyle>
              <Styled.ColStyle xs={6}>
                <TextField
                  value={item[0].value}
                  type={item[0].type}
                  label={item[0].label}
                  name={item[0].name}
                  color={color}
                  required="required"
                  handleTextInput={(name, value) => {
                    updateFields(name, value);
                    name === 'tradeReferencesCompanyName1'
                      ? setTradeReferencesCompanyName1(value)
                      : name === 'tradeReferencesCompanyName2'
                      ? setTradeReferencesCompanyName2(value)
                      : setTradeReferencesCompanyName3(value);
                  }}
                  handleTextError={(name, value) => {
                    updateErrorFields(name, value);
                  }}
                />
              </Styled.ColStyle>
              <Styled.ColStyle xs>
                <TextField
                  value={item[1].value}
                  type={item[1].type}
                  label={item[1].label}
                  name={item[1].name}
                  color={color}
                  required="required"
                  handleTextInput={(name, value) => {
                    updateFields(name, value);
                    name === 'tradeReferencesName1'
                      ? setTradeReferencesName1(value)
                      : name === 'tradeReferencesName2'
                      ? setTradeReferencesName2(value)
                      : setTradeReferencesName3(value);
                  }}
                  handleTextError={(name, value) => {
                    updateErrorFields(name, value);
                  }}
                />
              </Styled.ColStyle>
              <Styled.ColStyle xs={2}>
                <TextField
                  value={item[2].value}
                  type={item[2].type}
                  label={item[2].label}
                  name={item[2].name}
                  color={color}
                  required="required"
                  handleTextInput={(name, value) => {
                    updateFields(name, value);
                    name === 'tradeReferencesPhone1'
                      ? setTradeReferencesPhone1(value)
                      : name === 'tradeReferencesPhone2'
                      ? setTradeReferencesPhone2(value)
                      : setTradeReferencesPhone3(value);
                  }}
                  handleTextError={(name, value) => {
                    updateErrorFields(name, value);
                  }}
                />
              </Styled.ColStyle>
            </Styled.RowStyle>
          )
        )
      )}
    </>
  );
};
const mapStateToProps = (stateData) => {
  return {
    state: stateData,
  };
};

export default connect(mapStateToProps)(TradeReferences);
