import styled from 'styled-components';
import Datetime from 'react-datetime';
import { isNotNull } from '../../utils/validations';
import { AppColors } from '../../constants';

export const SubHeader = styled.h3`
  color: ${(props) => props.color};
  margin: 16px 0 16px 0;
  font-size: 14px;
  font-weight: bold;
  font-family: 'Arial';
`;
export const ParaText = styled.p`
  font-family: 'Arial';
  color: ${(props) => props.color};
  font-size: 16px;
`;
export const DateContainer = styled.div`
  background-color: #ededed;
  padding: 8px;
  display: block;
  clear: both;
  font-family: Arial;
  font-size: 12px;
  color: ${(props) => props.color};
`;
export const InputField = styled.input`
  width: -webkit-fill-available;
  border: 1px solid lightgray;
  font-family: Arial;
  font-size: 12px;
  padding: 2px;
  color: ${(props) => props.color};
`;
export const InputContainer = styled.label`
  background-color: #ededed;
  color: ${(props) => props.color};
  display: block;
  width: 100%;
  min-height: 31px;
  margin-bottom: 1px;
  padding: 8px;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
  clear: both;
  font-family: Arial;
`;
export const DatetimeStyle = styled(Datetime)`
  .form-control {
    color: ${(props) => props.color};
    width: 100%;
    height: 30px;
    font-family: Arial;
    font-size: 12px;
    color: #505050;
    padding: 8px;
    border-radius: 0 !important;
    border: ${(props) =>
      props.isSubmitted && !isNotNull(props.value)
        ? '1px solid red'
        : props.isSubmitted &&
          (props.errorStoreData['financialYearEndDate'] !== '' ||
            props.errorStoreData['financialYearEndDate'] === undefined) &&
          isNotNull(props.value)
        ? '1px solid red'
        : '1px solid lightgray'};

    background-color: ${(props) =>
      props.isSubmitted && !isNotNull(props.value)
        ? '#FCDFE0'
        : props.isSubmitted &&
          (props.errorStoreData['financialYearEndDate'] !== '' ||
            props.errorStoreData['financialYearEndDate'] === undefined) &&
          isNotNull(props.value)
        ? '#FCDFE0'
        : '#FFF'};
  }
`;
export const ErrorContainer = styled.div`
  color: ${AppColors.Red};
`;
